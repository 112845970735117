import { Component, Input, OnInit, Output } from '@angular/core';
import { ProductService } from 'src/app/services/product.service'
import { WishlistService } from 'src/app/services/wishlist.service';
import { WishlistItemService, Wishitems } from '@app/services/wishlist-item.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { map } from 'rxjs/operators';
import { Wish } from '@app/models/wish';
import { Product } from '@app/models/product';
import { Subscription } from 'rxjs';  
import { EventEmitter } from '@angular/core';
import { HeaderComponent } from '@app/ui/header/header.component';

@Component({
  selector: 'app-wishlistitem',
  templateUrl: './wishlistitem.component.html',
  styleUrls: ['./wishlistitem.component.scss'],
  providers: [EventEmitter,HeaderComponent]
})

export class WishlistitemComponent implements OnInit {

  //private subscription:Subscription

  
    @Input() productItem!: Product;
     
    @Input() wishItem!: Wish;

//wishitems is my database collection
  //Wishitems is my model/interface for db 
  wishitems: Wishitems[] = []


    //@Input() wishItem! : Product;
   

  constructor( private _wishlistitemService: WishlistItemService, private msg:MessengerService) { }

  ngOnInit(): void {



  }

  
//changed name here added item to name change function name to service added item
handleAddToWishlistitem(){
 /* this._wishlistitemService.addProductToWishlistItem (this.productItem).subscribe(() =>{
    alert("Add wish list item");

   this.msg.sendMsg(this.wishItem)
})*/
}

handleRemoveItemFromWishListitem(){
   this._wishlistitemService.removeFromWishlistItem(this.wishItem._id).subscribe(() =>
  console.log("Remove item from wishlist item" + "" + this.wishItem._id),
  //, + this.wishItem.id),

(err) => console.log(err)
  );
  //this.notifyDelete.emit(this.wishItem.id);
  console.log("trying to remove from wishlist ProductItem", this.wishItem._id);
  }

  handleReload(){
    alert("Handle Reload")
    this._wishlistitemService.buttonClick()
    
    
  }
  
 
}

