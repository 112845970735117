<div class=" continer dollsandbag">
    <p class="productlist-p">Welcome To DivasNDollsChi` your one stop shop providing you with the essentials for your beauty routine.<img class="img-fluid" style="height:50px"  src="assets/DollsandBags.png" alt="DivasandDolss Bag"></p>  
    
  </div>
  <div class=" container comp-layout"> 
    <div class="row">
      <div class="col-sm-12">
    <img class="img-fluid" src="assets/HomePage2-1-Final.png"   alt="landing page png">
     <div class="heroContent float-right"><strong><p>We are so excited to launch our e-commerce site. DivasNDollsChi`s expections will give our visitors a gret user experience with the belief they will become repeat customers. Engaging is what our merchandise offers,  uniqueness with eye catching fashion is our mission.</p></strong><br></div>
    </div>  
    </div>
  </div>

 
