import { Component, OnInit, Input } from '@angular/core';
import { Product } from '@app/models/product';
import { WishlistItemService } from '@app/services/wishlist-item.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { WishlistService } from '@app/services/wishlist.service';
import { CartService }  from 'src/app/services/cart.service';
import { Wish} from 'src/app/models/wish';


@Component({
  selector: 'app-purse-item',
  templateUrl: './purse-item.component.html',
  styleUrls: ['./purse-item.component.scss']
})
export class PurseItemComponent implements OnInit {

  @Input()  productItem!: Product;
  @Input() purseItem!: Product;

  @Input() addedToWishlist!: boolean;

  constructor(private msg:MessengerService, private _wishlistitemService:WishlistItemService, private wishlistService:WishlistService,private cartService: CartService  ) { }

  ngOnInit(): void {
  }


handleAddPurseToCart(){
    this.cartService.addPurseToCart(this.purseItem).subscribe(() =>{
     this.msg.sendMsg(this.purseItem)
  })
}

/*handleAddToWishlist(){
  alert("click works to added to purses wish list");
   this.wishlistService.addTowishlist(this.productItem.id).subscribe(() =>{
     this.addedToWishlist=true;

   })
   
}*/


handleAddPurseToWishlist(){
  alert("click works to add to purses wish list")
  this.wishlistService.addPurseTowishlist(this.purseItem._id).subscribe(() =>{
    this.addedToWishlist=true;

})

}



handleAddPurseToWishlistitem(){
  this._wishlistitemService.addPurseToWishlistItem(this.purseItem).subscribe(() =>{
    alert("Add wish list purse item");

    this.msg.sendMsg(this.purseItem)

  })

}

handeRemovePurseFromWishListitem(){
  this._wishlistitemService.RemovePurseFromWishlistItem(this.purseItem._id).subscribe(() =>
  console.log("Remove purse from wishlist item", + this.purseItem._id),
(err) => console.log(err)
  );

}



handleRemovePurseFromWishlist(){
  this.wishlistService.removePurseFromwishlist(this.purseItem._id).subscribe(() =>{
   alert("remove purse from wish list")
    this.addedToWishlist = false;
  })


}


}
