import { Component, NO_ERRORS_SCHEMA, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators,UntypedFormControl, AbstractControl, FormBuilder, FormGroup, AbstractControlOptions } from '@angular/forms';
import { first, map} from 'rxjs/operators';
//import { MustMatch } from './must-match.validator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MustMatch } from './must-match.validator';
import { AccountService, AlertService } from 'src/app/_services';
import { ApiService } from '@app/services/api.service';
import { AuthService } from '@app/services/auth.service';
import { formatCurrency } from '@angular/common';



@Component({ templateUrl: 'register.component.html' })
export class RegisterComponent implements OnInit {

    [x: string]: any;
    form!: UntypedFormGroup;     //colon assigns a type
    processing = false;     //assigns a value
    submitted = false;
    //errorMessage;
    errorMessage = '';
    userModel:any
    isSuccessful: boolean = false;
    isSignUpFailed = false;
    
    


    constructor(
        public formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private _http: HttpClient,
        private _api: ApiService,
        private _auth: AuthService,

    ) { }
    
  

    ngOnInit() {
        this.form = this.formBuilder.group({
            firstName:['', Validators.required],
            lastName: ['', Validators.required],
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', Validators.required],
            email:    ['', Validators.required],
        },
        {    
            validators: MustMatch('password', 'confirmPassword')
        } as AbstractControlOptions);  //gets rid od .group deprecated
    }





    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }
    

    onSubmit() {


       this.submitted = true;
       this.isSuccessful = false;
       
        this.processing = true;
        
        
        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

     

        console.log('ready to send');
 
       
 this.accountService.register(this.form.value)
           .pipe(first())
            .subscribe({
                next: () => {
                    console.log("Does it get to alertservice 1 ?")
                    this.isSuccessful = true;
                    this.alertService.success('Registration successful', { keepAfterRouteChange: true });
                   

                    this.router.navigate(['../login'], {queryParams:   {isSuccessful: true} })
                  // this.router.navigate(['../login'], { relativeTo: this.route });
                   
                    console.log("Must navigate")
                },
                error: error => {
                    this.alertService.error(error);
                    console.log("whats happening here")
                    this.loading = false;
                }

                
            });
             
            console.log("Trying to get to back-end service");

            
        }


         

}


