<h1 style="text-align:center">Sales</h1>
<!--<div *ngIf="cartitems$.length === 0"class="alert alert-info">Your Cart is Empty</div>--> 
    
<div class="container" style="height:300px">
    <div *ngIf="saleitem$.length === 0"class="alert alert-info">There are No Sales</div> 
    <div>
        <!--<img class=" img-fluid shacker"  [src]="saleItem.imageUrl" alt="Clothing" />-->
    </div>
    <div class="btn-group">
        <button type="submit"   class="productitem-sub-btn" style="border:0; outline:0; background-color:transparent; padding-bottom: 100px; margin-left: 800px"><img  src="assets/shopping_cart_outline001.png" class="productitem-img" style="background-color:none; color:#D30169; font-size:5px; cursor:pointer; outline:none; outline:0; border:0"></button>
      </div>
</div>