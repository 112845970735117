<div class="row">
    <div class="col-md-4 offset-md-4">
    <div class="card">
        <h4 class="card-header">Contact Form</h4>
        <div class="card-body">
    <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)"><div class="form-group">
    <label for="Email">Email</label>
    <input type="email" class="form-control" name="Email" aria-describedby="emailHelp" placeholder="Enter email"formControlName="Email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    </div>
    <div class="form-group">
    <label for="fullname">Full Name</label>
    <input type="text" class="form-control" name="Fullname" placeholder="Full Name" formControlName="Fullname">
    </div>
    <div class="form-group">
    <label for="comment">Comment</label>
    <textarea class="form-control" formControlName="Comment" name="Comment">
    </textarea>
    </div>
    <button type="submit" class="btn btn-primary" [disabled]="!FormData.valid">Submit</button>
    </form>
    </div>
    </div>
    </div>
</div>
