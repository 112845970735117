import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItem } from '../models/cart-item';


export interface Cartitems{
  _id: string,
  name: string,
  size: string,
  qty: number,
  price: number,
  imageUrl:string
}


@Injectable({
  providedIn: 'root'
})
export class DataService {

  //This is a service to share data between components

  qty: any;
  itemTotal$ = 0;
  cartitems$: Cartitems[] = [];

  private cartItemsSource = new BehaviorSubject<Cartitems[]>([]);
  currentCartItems = this.cartItemsSource.asObservable();

  constructor() {

   }

   public calNumberOfItems(){
    alert("Trying to get tolal items");

    for(this.qty=0; this.qty < this.cartitems$.length; this.qty++){
     // alert("The toatl is " + this.qty) 
      this.qty
    }
    this.itemTotal$ = this.qty;
    this.cartItemsSource.next(this.qty) 
    
    

   
    
  }

  public getTotalItems(cartitems$: Cartitems[]) {

    this.cartItemsSource.next(cartitems$);
  }

    


getItemsInCart(): Observable<Cartitems[]> {

  return this.cartItemsSource.asObservable();



}

}
