import { Component, inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, AbstractControlOptions, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MustMatch } from '../must-match.validator';
import { AccountService, AlertService } from 'src/app/_services';
import { ResetPassword } from '@app/models/reset-password';


enum TokenStatus {
  Validating,
  Valid,
  Invalid
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export  class ResetPasswordComponent implements OnInit {

  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  token!: string;
  emailToken!: string;
  emailToReset!: string
  form!: UntypedFormGroup;
  loading = false;
  submitted = false;
  password!: string;
  newPassword!: string;
  confirmpassword!: string;
  id!: string



  
  


  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private accountService: AccountService,
      private alertService: AlertService
  ) { }

  ngOnInit() {
      this.form = this.formBuilder.group({
          password: ['', [Validators.required, Validators.minLength(6)]],
          confirmPassword: ['', Validators.required],
      }, {
          validator: MustMatch('password', 'confirmPassword')
      }as AbstractControlOptions);

      //this.route.params.subscribe
      this.route.params.subscribe(val =>{

     
        this.token = val['token'];
        alert("Client side What is  Token now!" + "" + "" + ""+ this.token)
      
      });

     

       //const token = this.route.snapshot.queryParams['token'];
      // remove token from url to prevent http referer leakage


      this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

      this.accountService.validateResetToken(this.token)
          .pipe(first())
          .subscribe({
              next: (result) => {
                alert("What is result" +  result)
                  this.token = this.token;
                  this.tokenStatus = TokenStatus.Valid;
              },
              error: () => {
                  this.tokenStatus = TokenStatus.Invalid;
              }
          });
  }


  
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }


  onSubmit() {
      this.submitted = true;
      this.password = this.form.value.password;
      this.confirmpassword = this.form.value.confirmPassword
       
      alert("Am I get form value" + "" + "" +this.password);
      alert("Am I getting confirmPassword" + " " + " " +this.confirmpassword)
      alert("OnSubmit what is token" + "" +this.token)
     
      

      /*let resetObj ={
        token:this.token, 
        password: this.form.value.password


      }*/

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }


      if(!this.form.invalid){

        
             this.password = this.form.value.password;
             
             this.confirmpassword = this.form.value.confirmPassword;

           alert("New Password value" + " " + " "  + this.password);
           alert("Confirm Password" + " " + " " + this.confirmpassword)
      }
      


      let resetObj ={
        token:this.token, 
        password:  this.form.value.password


      }
       
      

      alert("whats in resetObj" + "" +resetObj)

      this.loading = true;
      this.accountService.resetPassword(resetObj)
      /*this.accountService.resetPassword(this.token, this.f.password.value, this.f.confirmPassword.value)*/
         // .pipe(first())
          .subscribe({
              next: () => {
                  this.alertService.success('Password reset successful, you can now login', { keepAfterRouteChange: true });
                  this.form.reset();
                  this.router.navigate(['../../login'], { relativeTo: this.route });
              },
              error: error => {
                  this.alertService.error(error);
                 // this.loading = false;
              }
          });
  }

}
