import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { wishlistitemUrl } from 'src/app/config/api';
import { map, switchMap } from 'rxjs/operators';
import { ProductItemComponent } from '@app/shopping-cart/product-list/product-item/product-item.component';
import { Observable, of } from 'rxjs';
import { catchError} from 'rxjs/operators';
import {Product} from 'src/app/models/product';
import {Wish} from 'src/app/models/wish';
import { PursesService } from '@app/services/purses.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
//import { UrlWithStringQuery } from 'url';
import { HeaderComponent } from '@app/ui/header/header.component';
import { AnyCnameRecord } from 'dns';
//import { Products } from './product.service';
import { BehaviorSubject,Subject } from 'rxjs';


export interface Wishitems{
  _id: string,
  name: string
 
}


export interface Products{
  _id: string,
  name:string
}


@Injectable({
  providedIn: 'root'
})
export class WishlistItemService {

 product:any;
 //id!: number;
 

 wishlistitemUrl = 'http://localhost:4000/wishlistitem';
 
rootUrl = 'api';
 
addedToWishlist= new BehaviorSubject<boolean>(false);
wishitems$ = new Subject<Wishitems[]>();


get _wishitems$(){
  return this.wishitems$
}


 
 constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) { }

  

  /*getWishlistitem(): Observable<Wish[]>{
    //console.log("Inside service getWishlistItem");
     return this.http.get<Wish[]>(wishlistitemUrl).pipe(
     map((result: any[]) => {
       let wishItems: Wish[] =[];

       alert("Trying to see when this is called")

       for(let item of result) {
        
        let productExists = false

        for(let i in wishItems){
         
          if(wishItems[i].productId === item.product._id){
            
           wishItems[i].qty++;
           productExists = true
           break;
       } 
     }
 
     
        if (!productExists){

          wishItems.push(new Wish(item.id, item.product, item.name));
        }
        
       }
          // alert("what is this holding", )

           return wishItems;
     })
    );

  }*/


  getWishlistitem(): Observable<Wishitems[]>{
    //console.log("Inside service getWishlistItem");
     return this.http.get<Wishitems[]>(this.rootUrl + '/getProducts/wishitems')
     
     
  }    
 

  
 refreshComponent(x:any): Observable<any>{ 
    this.router.navigate(['/wishlistlist']);
    //alert("Trying to reload");
    return  x;                            //Observable returns a value not sure this will work have to look at it upstream 
 }


  
 /* addProductToWishlistItem(product:Product):Observable<any>{
    alert("Inside wish list list service");
    return this.http.post(wishlistitemUrl, {product});
  }*/


  addProductToWishlistItem(products:Products, _id:String):Observable<any>{
    //alert("Inside wish list list service");
   return this.http.post(this.rootUrl + '/getProducts/addwishitems' + _id, {products})

  } 

  removeFromWishlistItem( _id:string){

    return this.http.delete<Wishitems[]>(this.rootUrl + '/getProducts/removewishitem/' + _id)   

  }
    
  

  
//This from popup
  saveData(product:Product, _id:String):Observable<any>{

   // alert("Trying to save Data from popup");
    //alert(product);

     return this.http.post(this.rootUrl + '/getProducts/popup' + _id,  {product})

  }
  


  getDataFromPopup(product:Product, _id:String):Observable<any> {
    // Get the data from the popup.
    //alert("Inside get data")
    //alert("What is _id" + _id)
    return this.http.post(this.rootUrl + '/getProducts/popup/' + _id, {product})
  
    // return this.http.get('/data');
    /*return this.http.post(wishlistitemUrl, '/data', {

    }).subscribe(res => {
      alert(res);
      console.log("Is this returning anything")
    });*/
       
    
  }

 

//newly added because of duplicate calls one from header pop up
  addProductItemToWishlistItem(product:Product):Observable<any>{
  return this.http.post(wishlistitemUrl, {product});
}

  addShoeToWishlistItem(product:Product):Observable<any>{
   console.log("Service for Add shoe to wish list item");
   // alert("Service for Add shoe to wish list item");
    return this.http.post(wishlistitemUrl, {product});

  }

  addPurseToWishlistItem(product:any):Observable<any>{
    
    return this.http.post(wishlistitemUrl, {product});

  }

   // Delete works, taking only the values from the most recently projected inner observable.
  RemoveProductFromWishlistItem(_id:string):Observable<any>{   //changed from <Wish[]>
    //alert("Remove from wishlist item");
    return  this.http.delete<Wishitems[]>(this.rootUrl + '/getProducts/removewishitem' + _id)   
     // switchMap(()=> this.getWishlistitem()),
      catchError(_err => of (null))
       
       
  }

  buttonClick() { 
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });

      //alert("Should Reload");
  } 


RemoveShoeFromWishlistItem(id:number):Observable<any>{  //took out Wish[] took away error in strict
  this.getWishlistitem();
    return this.http.delete<void>(`${this.wishlistitemUrl}/${id}`)

    .pipe(catchError(_err => of (null))
     
    );
    

 }

 

 RemovePurseFromWishlistItem(id:string):Observable<any>{     // make same as precious  took out Wish[]
  return this.http.delete<void>(`${this.wishlistitemUrl}/${id}`)
  .pipe(catchError(_err => of (null))
   
  );
}



}
