<h1 class="aboutus-h1" >About DivasNDollsChi`</h1>
<div class="container about-container">
  <div class="img-fluid" style="background-image:url('../../assets/My-Deniseia_Croped_1_50.jpg'); height:594px; width:585px; display:block; margin-left:auto; margin-right:auto;"></div>
    <div class="row about-row">
     
    <!--<div class="col-3">--> 

      <div class="col-3 about-col1" >
        <img src="assets/newbow.png" alt="small bow" class="about-img1" />
          <h1 class="aboutus-h2"><b>DivasNDollsChi&#39;</b></h1>
            <p>Established in May 2020 as just an idea to doll up every woman to the diva that already dwells inside of you.</p>
      </div>
       <div class="col-3 about-col2">
        <img src="assets/newbow.png" alt="small bow" class="about-img2"/>
          <h1 class="aboutus-h3"><b>Passion</b></h1>
            <p>The passion for fashion is what we want to achieve here at Divas N Dolls Chi&#39; along  with styles of all kinds that will have eye catching appeal with quality and affordable pricing.</p>
       </div>
        <div class="col-3 about-col3">
          <img src="assets/newbow.png" alt="small bow"  class="about-img3" />
            <h1 class="aboutus-h4"><b>Goal</b></h1>  
              <p>Always to stay abreast of the latest trends for seasons and events that will surely compliment elegance with destinction. </p>
        </div>

        
        <!--<div class="col-3" style=" border:2px solid pink; padding:10px; border-radius: 40px 20px; margin-left:10px">
          <h1 style="font-size: 14px; color:#d30169; text-align: center;"><b>Vision</b></h1>  

        </div>-->
        
     

<!--</div>-->  
  </div>
 

</div>
