import { Component, Input, OnInit } from '@angular/core';
import {Product} from 'src/app/models/product';
import {ProductService, Products} from 'src/app/services/product.service';
import { Sale } from '@app/models/sale';
import { SaleitemComponent } from './saleitem/saleitem.component';
import { Sales } from 'src/app/services/sale.service';



@Component({
  providers:[SaleitemComponent],
  selector: 'app-salelist-list',
  templateUrl: './salelist-list.component.html',
  styleUrls: ['./salelist-list.component.scss']
})
export class SalelistListComponent implements OnInit{
 
//products is my database collection
  //Products is my model/interface for db 
  sales: Sales[] = [];
 
  saleList: Sale[] = [];

  saleitem$ :   Sales[] = [];
 

  @Input() saleItem!: Sale;



  currentIndex = -1;

  p = 1;
  count = 0;
  pageSize = 6;
  pageSizes = [6, 12, 18];



  constructor(){  }

  ngOnInit(): void {
   // throw new Error('Method not implemented.');
  }



 

  getRequestParams( p: number, pageSize: any): any {
    // tslint:disable-next-line:prefer-const
   
    //was let param = {}; got error regarding {} being a type index type
    let params:any = {};

    if (p) {
      params[`p`] = p - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }


  handlePageChange(event: number): void {
    console.log("please Get Page");
    this.p = event;
    //this.loadProducts();
    
  }


}
