<p></p>
<div class="test">
    <div class="container" style="height:100%; width:300px;">
      <div class="row" style="margin-top: 20px">
       <div class="col-6">
          <img class="img-fluid shacker" [src]="purseItem.imageUrl" alt="Purses"/>
            <div class="col-4">
       <!--<p class="card-text">-->
         <p class="float-left" style="text-align:left; width:140px">{{purseItem.name}}</p>
       <p  style="text-align:left; width:100px;">
         <strong>{{ purseItem.price | currency }}</strong>
          <i *ngIf ="addedToWishlist" class="fas fa-heart float-right"  (click)="handleRemovePurseFromWishlist()"></i>
          <i *ngIf ="!addedToWishlist" class="far fa-heart float-right" (click)="handleAddPurseToWishlist(); handleAddPurseToWishlistitem();"></i>
       </p>

         <p class="card-text" style="text-align:left; width: 150px">{{purseItem.description | slice: 0:20}}...</p>
       <div class="d-flex justify-content-between align-items-left">
         <div class="btn-group">
           <button style="border:0; outline:0; background-color:transparent; padding-bottom: 60px" (click)="handleAddPurseToCart()"><img  src="assets/shopping_cart_outline001.png" style="background-color:none; color:#D30169; font-size:5px; cursor:pointer; border:0; outline:0;"></button>
         </div>
         
       </div>
     </div>
   </div>
   </div>
   </div>
     </div>