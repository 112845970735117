import { Component, Input } from '@angular/core';
import { Sale } from '@app/models/sale';
import { Sales } from 'src/app/services/sale.service';


@Component({
  selector: 'app-saleitem',
  templateUrl: './saleitem.component.html',
  styleUrls: ['./saleitem.component.scss']
})
export class SaleitemComponent {

  saleitem$ :   Sales[] = [];
  sales : Sale[] = [];

  @Input() saleItem!: Sale;





  

}
