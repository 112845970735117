import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { wishlistUrl } from 'src/app/config/api';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class WishlistService {







  constructor(private http: HttpClient) { }




  getWishlist(){
    return this.http.get(wishlistUrl).pipe(
    map((result: any) =>{   //was any[]
      let productIds: any[] = []
      result.forEach((item: { id: any; }) => productIds.push(item.id))
      return productIds;

    })
    )

  }

  


 addTowishlist(productId: string){

    return this.http.post(wishlistUrl, {id: productId} )
    

  }



  addShoeTowishlist(shoeId:number){
    return this.http.post(wishlistUrl, {id: shoeId} )

  }

  addPurseTowishlist(purseId:string){
    return this.http.post(wishlistUrl, {id: purseId} )


  }

  removeFromwishlist(productId: string | number){

    return this.http.delete(wishlistUrl  + '/' + productId)

  }

  removeShoeFromwishlist(shoeId: string | number){
    return this.http.delete(wishlistUrl  + '/' + shoeId)

  }


  removePurseFromwishlist(purseId: string | number){
    return this.http.delete(wishlistUrl  + '/' + purseId)
  }


  



}
