import { Component, OnInit } from '@angular/core';
import {Product} from 'src/app/models/product';
import {Wish} from 'src/app/models/wish';
import {ProductService, Products} from 'src/app/services/product.service'
import { WishlistService } from 'src/app/services/wishlist.service';
import { WishlistItemService } from '@app/services/wishlist-item.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { CartService }  from 'src/app/services/cart.service';
//import { StringDecoder } from 'string_decoder';
import { ProductItemComponent } from './product-item/product-item.component';
import { STRING_TYPE } from '@angular/compiler';
import { eventNames } from 'process';
//import { AnyAaaaRecord } from 'dns';
import { take, tap } from 'rxjs/operators'
import { error } from 'protractor';

@Component({
  providers:[ProductItemComponent],
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  //products is my database collection
  //Products is my model/interface for db 
  products:Products[] = [];
 
  productList: Product[]= [];
  wishlist: number[] = [];

  name: string | undefined;
   query:any

wishItem: any[] = [];

//wishitemList: Wish[] =[];
//wishlistList: Wish[] = [];

  currentProduct= null;
  currentIndex = -1;

  p = 1;
  count = 0;
  pageSize = 6;
  pageSizes = [6, 12, 18];
  
//private msg: MessengerService, take this out for now

  constructor(private productService: ProductService,
             
             ) { }


  //Once component is loaded you are getting the data
  ngOnInit(): void {  // took this out : void
   

    this.testloadProducts();  //need to take this out don't need it

  }

  loadProducts(){
    //alert("loadng Product");
    this.productService.getProducts().subscribe((products) =>{   //should take out par for subscribe
      this.productList = products;
      
      
    })
  }


  allLoadProducts(){
  //this is another need to make products$ for more reactive progrmaiing will fix later
  //alert("Trying to load Products");
  this.productService.getallProducts().
    pipe(tap((products)=>
      (this.products = products)))

  }

  testloadProducts(){
   // alert("Trying to load products")
    this.productService.getallProducts().subscribe((pro) =>{
      this.products = pro;
      
      
    })

    
  }





  getRequestParams( p: number, pageSize: any): any {
  
    
    let params:any = {};

    if (p) {
      params[`p`] = p - 1;
    }

    if (pageSize) {
      params[`size`] = pageSize;
    }

    return params;
  }



  handlePageChange(event: number): void {
    //alert("please Get Page");
    this.p = event;
    this.loadProducts();
    
  }

  handlePageSizeChange(event: { target: { value: number; }; }) {
    this.pageSize = event.target.value;
    this.p = 1;
   
    
  }

  setActiveTutorial(productList: null, index: number): void {
    this.currentProduct = productList;
    this.currentIndex = index;
  }

}
