

<div class="mainview" >  
    <app-header></app-header>
    <alert></alert>
     <router-outlet></router-outlet> 
      <app-footer></app-footer>
      <div class="content"><span class="visually-hidden">DivasandDollsChi app is running!</span></div>
</div> 

    

 
