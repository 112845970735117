import { Component, OnInit, Input } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Product } from 'src/app/models/product';
import { HeaderComponent } from '../header.component';
import { ImageService } from '@app/services/image.service';

export interface Products{
  _id: string,
  name: string
}



@Component({
  selector: 'app-app-modal',
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.scss']
})
export class AppModalComponent implements OnInit {

 
 @Input()
  modalItem!: Products;  //assigment assertion
 @Input() result: any; 


 //not being used 

  constructor() { }

  ngOnInit(): void {
  }

}
