<div class="container">
  <div class="card filters"> 
   <div class="card-header"><strong>Filters</strong></div>   
   <div class="card-body">   
 <form>  
  <div class="form-group">
    <label for="from">from</label> 
    <input type="number"  id="from" class="form-control">
  </div>

  <div class="form-group">
    <label for="to">to</label> 
    <input type="number"  id="to" class="form-control">
  </div> 
  <button class="btn btn-primary btn-block btn-sm">Filter by Price</button>

 </form>
   </div>
  </div>
</div>
