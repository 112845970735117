import { Injectable } from '@angular/core';
import{HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import{map, catchError, tap} from 'rxjs/operators'
import {MessengerService} from 'src/app/services/messenger.service';
import {productsUrl} from '@app/ui/header/api'
import { productUrl } from '@app/config/api';

export interface Products{
  _id: string,
  name: string
 
}


@Injectable({
  providedIn: 'root'
})
export class SearchService {

 
  constructor(private http:HttpClient) { }

  rootUrl = '/api'
  
  
 
 
  searchProducts(query:string) {
    return this.http.post<{payload: Products[]}>(this.rootUrl + '/getProducts', {payload: query}, {
     
     headers: new HttpHeaders({'Content-Type': 'application/json'})       
    }).pipe(
      map(data => {
        console.log('API Response => ', data);
        return data.payload
      })
    
   );
         
     
    }
  }
