<div class="row">
 <div class="col-md-4 offset-md-4">
  <div class="card" style="margin-top: 25px">
<h3 class="card-header">Reset Password</h3>
<div class="card-body">
    <div *ngIf="tokenStatus == TokenStatus.Validating">
        Validating token...
    </div>
    <div *ngIf="tokenStatus == TokenStatus.Invalid">
        Token validation failed, if the token has expired you can get a new one at the <a routerLink="../forgot-password">forgot password</a> page.
    </div>
    <form *ngIf="tokenStatus == TokenStatus.Valid" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="password">New Password</label>
            <input type="password"  name="password" id="password" [ngClass]="f.password" formControlName="password" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            <i class="fas fa-user" style="position:absolute; top:120px; left:30px; color:#007BFF"></i>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required</div>
                <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group">
            <label for="confirmPassword">Confirm New Password</label>
            <input type="password" name="confirmPassword" id="confirmPassword" formControlName="confirmPassword" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
            <i class="fas fa-user" style="position:absolute; top:207px; left:30px; color:#007BFF"></i>
            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
            </div>
        </div>
        <div class="form-group">
            <button  class="btn btn-primary" style="font-size: 1vw">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Reset Password
            </button>
            
            <a routerLink="../../home" class="btn btn-link" style="font-size:1vw;">Cancel</a>
          
        </div>
    </form>
</div>
</div>
</div>
</div>