<div class="test">
    <div class=" productitem-container" >
      <div class="row wishlistitem-row">
       <div class="col-5" >  
        
         <img  class="img-fluid"  [src]="wishItem.imageUrl"   alt="wishlist item"/>
        
         <div class="card-body col-sm-12">
      	
         <p class="card-text wishlistitem-card-text">{{wishItem.name}}</p>
         <p class="card-text wishlistitem-card" >
         <strong>Price:&nbsp; {{ wishItem.price | currency }}</strong>
          </p>
          <p class="card-text myDIV  wishlistitem-card-tip">Details:</p>
         <p class="card-text hide wishlistitem-card-font">{{wishItem.description}}</p>
        
          <p  class="wishlistitem-remove" (click)="handleRemoveItemFromWishListitem();handleReload()"><span>Remove</span>
            &nbsp;<i class="fa fas fa-trash-alt" aria-hidden="true"></i>
          </p>
          
        </div> 
     </div>
    </div>
   </div>
   </div>
   

