import { Component, OnInit, Input } from '@angular/core';
import {ProductService} from 'src/app/services/product.service'
import { MessengerService } from 'src/app/services/messenger.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { WishlistItemService, Wishitems} from '@app/services/wishlist-item.service';
import { Wish} from 'src/app/models/wish';
import {Product} from 'src/app/models/product';
import { isNgTemplate } from '@angular/compiler';

 

@Component({
  selector: 'app-wishlist-list',
  templateUrl: './wishlist-list.component.html',
  styleUrls: ['./wishlist-list.component.scss'],
  //providers:[WishlistItemService]
})
export class WishlistListComponent implements OnInit {


  //wishitems is my database collection
  //Wishitems is my model/interface for db 
  wishitems: Wishitems[] = [];

  
  @Input() product: any;

  productList: Product[]= [];
  wishitemList: Wish[]= [];
  //wishItems = [] which gives me a never type which indicates that a value will never happen
  wishItems:  Wish[] =[];


  p = 1;
  count = 0;
  pageSize = 6;
  pageSizes = [6, 12, 18];
  
 

  constructor( private msg: MessengerService, private productService: ProductService,
   private wishlistService: WishlistService,
   private _wishlistitemService: WishlistItemService  ) { }
 
   ngOnInit(): void {
     //.loadwishlistItem()
     this.loadwishlistList()
   }
 
 
 

loadwishlistList(){
 // alert("Loading wish list item from back end");
  this._wishlistitemService.getWishlistitem().subscribe((item) => {
   // console.log("A wish List  Item" + "What is this " + item);
    this.wishitems=item;
  // alert("A wish List  Item" + item);

})

}



testloadwishlistlist(){
  console.log("test")

}


handlePageChange(event: number): void {
 // alert("please Get Page");
  this.p = event;
  this.loadwishlistList();
  
}
 

}

