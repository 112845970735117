import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  subject = new Subject()


  constructor() { }

  sendMsg(product: unknown){   //infer from usage, unknown is the set of all possible values.
    console.log(product)

    this.subject.next(product)  //Triggering an event

  }

  getMsg(){

    return this.subject.asObservable()  //An itetface to trigger a varity of ansyncronous operation using a service


  }
}
