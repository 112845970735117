import { Component, OnInit,ViewChild } from '@angular/core';
import {  Input, Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { CartService, Cartitems } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import {ProductService} from 'src/app/services/product.service';
import { render } from 'creditcardpayments/creditCardPayments';
import { CartItemComponent } from 'src/app/shopping-cart/product-list/cart-item/cart-item.component';
import { CartComponent } from 'src/app/shopping-cart/product-list/cart/cart.component';

declare var PayPalButton: any;

@Component({
  //providers:[CartItemComponent],
    providers:[CartComponent],
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss']
  })


  export class CheckoutComponent implements OnInit {
   
 //cartitems is my database collection
  //Cartitems is my model/interface for db 
  cartitems: Cartitems[] = []

    

 cartComponent!: CartComponent;
    
  @Input()
  product!: CartItem;
  @Input()
  cartItem!: CartItem;
   
 
  size: any;
  qty!: number;
  price!: number;
 
   cartItems:CartItem[] = [];
   cartTotal = 0;
   itemTotal$ = 0;  
   shipping = 8.00;
   estimatedTax = 0;
   myValue: any;
  
   
   //Take out services not being used will cause problem private route: ActivatedRoute, private router: Router,private productService: ProductService,

    constructor(private msg: MessengerService, 
          private cartService:CartService,  private comp:CartComponent) {}


    ngOnInit() {
      
      this.handleSubscription(); 
        this.loadCartItems();

    }
   

       handleSubscription(){

        this.msg.getMsg().subscribe((product: unknown) => {         //This is an obsevable, unknown is the set of all possible values.
           console.log(this.msg)
           this
          
         // this.addProductToCart(product) 
         // this.loadCartItems();
        })

      }

       loadCartItems(){

        this.cartService.getCartItems().subscribe((items) => {
         this.cartitems = items;
          this.calcCartTotal();
           this.calNumberOfItems();
      })
      }

      calcCartTotal() {
      
      
         this.cartitems.forEach(item => {
           this.cartTotal += ( item.qty *item.price); 
           
         })

         this.cartTotal +=  this.shipping;
         this.myValue = this.cartTotal
         render(
          {
            id:"#paypal-button-container",
            currency: "USD",
            value: this.myValue,

            onApprove: (details) =>{
             // alert("Transaction Suceessfull")
              console.log(details)
               console.log(this.myValue);
                 this.comp.handleClearCart();
                  //this.router.navigate(["/home"]); 
              
               
           }
         
            
          } 
       );
       }
      
      
     
       calNumberOfItems(){

        //alert("Trying to get tolal items");
        for(this.qty=0; this.qty < this.cartitems.length; this.qty++){
         this.qty;
        }
        this.itemTotal$ = this.qty;
      
      }

     
  }