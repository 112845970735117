import{ HttpClientModule, HttpRequest, HTTP_INTERCEPTORS} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'

//import { HttpModule } from '@angular/http'
import { HttpClient } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ErrorHandler} from '@angular/core';
import {RouterModule} from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from  'src/app/_helpers/auth.guard'

//import { Ng2SearchPipeModule } from 'ng2-search-filter';


//services
import { WishlistItemService } from './services/wishlist-item.service';
import { DataService } from './services/data.service';
import { CartService } from './services/cart.service';
import { ContactService } from './services/contact.service';
import { ImageService } from './services/image.service';
import { ProductService } from './services/product.service';
import { PursesService } from './services/purses.service';
import { SearchService } from './services/search.service';
import { WishlistService } from './services/wishlist.service';


// used to create fake backend
import { fakeBackendProvider } from './_helpers';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
//import { AlertComponent } from './_components';
import { AlertModule }    from './_components';



import { AppRoutingModule } from './app-routing.module';
import { NgbActiveModal, NgbModalRef, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './ui/header/header.component';
import { FooterComponent } from './ui/footer/footer.component';
import { LayoutComponent } from './ui/layout/layout.component';
import { ClothingComponent } from './shopping/clothing/clothing.component';
import { HairproductsComponent } from './shopping/hairproducts/hairproducts.component';
import { ToolsComponent } from './shopping/tools/tools.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';
import { Clothing3Component } from './shopping/clothing3/clothing3.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { FiltersComponent } from './shopping-cart/filters/filters.component';
// { CartComponent } from './shopping-cart/cart/cart.component';
import { ProductListComponent } from './shopping-cart/product-list/product-list.component';
//import { CartItemComponent } from './shopping-cart/cart/cart-item/cart-item.component';
import { ProductItemComponent } from './shopping-cart/product-list/product-item/product-item.component';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register.component';
import { CheckoutComponent } from './shopping-cart/checkout/checkout.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AuthModule } from './account/auth.module';
import { ContactFormComponent } from './contact-form/contact-form.component';

import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { VerifyEmailComponent } from './account/verify-email/verify-email.component';
import { WishlistListComponent } from 'src/app/shopping-cart/product-list/wishlist-list/wishlist-list.component';
import { WishlistitemComponent } from './shopping-cart/product-list/wishlist-list/wishlistitem/wishlistitem.component';
//import { ShoeListComponent } from './shopping-cart/product-list/shoe-list/shoe-list.component';
//import { ShoeItemComponent } from './shopping-cart/product-list/shoe-list/shoe-item/shoe-item.component';
import { PurseListComponent } from './shopping-cart/product-list/purse-list/purse-list.component';
import { PurseItemComponent } from './shopping-cart/product-list/purse-list/purse-item/purse-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ProductFilerPipe } from './shopping-cart/product-list/product-filter.pipe';
import { CartComponent } from './shopping-cart/product-list/cart/cart.component';
import { CartItemComponent } from './shopping-cart/product-list/cart-item/cart-item.component';
import { AppModalComponent } from './ui/header/app-modal/app-modal.component';
import { AccountService, AlertService } from './_services';
import { NO_ERRORS_SCHEMA } from '@angular/compiler';
import { AlertComponent } from './_components';
import { WelcomeComponent } from './shopping-cart/welcome/welcome.component';
import { SalelistListComponent } from './shopping-cart/salelist-list/salelist-list.component';
import { SaleitemComponent } from './shopping-cart/salelist-list/saleitem/saleitem.component';







@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutComponent,
    ClothingComponent,
    HairproductsComponent,
    ToolsComponent,
    AboutusComponent,
    ContactComponent,
    PageNotFoundComponent,
    Clothing3Component,
    ShoppingCartComponent,
    FiltersComponent,
    CartComponent,
    ProductListComponent,
    CartItemComponent,
    ProductItemComponent,
    LoginComponent,
    RegisterComponent,
    CheckoutComponent,
    ContactFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    VerifyEmailComponent,
    WishlistListComponent,
    WishlistitemComponent,
    //ShoeListComponent,
    //ShoeItemComponent,
    PurseListComponent,
    PurseItemComponent,
    ProductFilerPipe,
    AppModalComponent,
    WelcomeComponent,
    SalelistListComponent,
    SaleitemComponent,

   
   // Ng2SearchPipeModule 
    
    
   
    
   
    
    
    /*SearchBarComponent,*/
    //AlertComponent,
    
    
    
  ],

  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AuthModule,
    AlertModule,
    //PinchZoomModule,
    
    
    
    //NgbModule.forRoot(), 
    //NgbModule.forRoot(), 
//RouterModule.forRoot([], {}),
    RouterModule.forRoot([]),

    JwtModule.forRoot({
      config: {
        tokenGetter: function  tokenGetter() {
             return     localStorage.getItem('access_token');},
             allowedDomains: ['localhost:4100'],                     //change from whitelisted domains
             //In this array, you can add any domains that are allowed to receive the JWT like public

             //:['http://localhost:80/auth/login']   //change from blacklisted routes
            //In this array, you can add routes that are not allowed to receive the JWT token.
      }
    }),
    BrowserAnimationsModule

  ],

  //exports: [HttpClientModule],

 //Angular providers tell the Angular Dependency Injection (DI) system how to get a value for a dependency
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend 
    // comment out the line below once I get back end worrking and it shouod hooked up with node + Mongoose api
    //fakeBackendProvider,
    CartService,
    ContactService,
    WishlistItemService,
    DataService,
    ImageService,
    NgbActiveModal,
    ProductService,
    PursesService,
    SearchService,
    WishlistService,
    AccountService,
    //AlertService
    
    
  ],
  
  bootstrap: [AppComponent]
})

//entryComponents:[CartComponent]
export class AppModule { }
