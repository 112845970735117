import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class AppserviceService {

 
   private data = new BehaviorSubject('');
   currentData = this.data.asObservable()


  constructor() {
   
    
   }


  updateMessage(item:any){
    this.data.next(item);
  }

  
  
}
