          
 <!--<div class="test">-->
   <div class=" productitem-container" > <!--Took bootstrap container out in order to use this class-->
    <!--<alert></alert>-->
     <div class="row productitem-row">
       <div class="col-6">
          
        <img class=" img-fluid shacker"  [src]="productItem.imageUrl" alt="Clothing" />
         
         
            <div class="col-sm-12">
              <p class="float-left productitem-para">{{productItem.name}}</p>
              <form name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="float-left form-check productitem-form" >Size:
               
                <label>
                  <input class='hiddenInput' id="a">  
                <label class="form-check-label checkbox-inline productitem-label" for="small" >  
                <input  type="radio"  name="sizeBy" formControlName="sizeBy" id="small"   [ngClass]=f.sizeBy value="S" required>
               S</label>
              
           
             
                <label class="form-check-label checkbox-inline productitem-label" for="medium">
                <input type="radio" name="sizeBy" formControlName="sizeBy" id="medium"   [ngClass]=f.sizeBy value="M" required>
                M</label>
                
             

                <label class="form-check-label checkbox-inline productitem-label" for="large">
                <input  type="radio" name="sizeBy" formControlName="sizeBy" id="large" [ngClass]=f.sizeBy value="L" required>
               L</label>
               
               


               <!--<label class="form-check-label checkbox-inline productitem-label" for="large" style="padding: 4px" >
                <input  type="radio" name="sizeBy" formControlName="sizeBy" id="large" [ngClass]=f.sizeBy value="L" required>
               XL</label>-->

              </label><!--End top lable--> 
               

               
                 
                <!--<div *ngIf="f.sizeBy.errors?.required">Size is required</div>--> 
                <div *ngIf="f.sizeBy.errors?.required" class="text-danger" style="padding:0"><b>You Must Choose A Size</b></div>

                <p class="card-text myDIV product-details" >Details</p>
                <p class="hide">{{productItem.description}}</p>
              
                <!--<p *ngIf="products.length !==0" class="alert alert-info" >In Stock</p>-->

              <p class=" card-text productitem-price">
                <strong>{{ productItem.price | currency }}</strong>
                 <i *ngIf ="addedToWishlist" class="fas fa-heart fa-lg float-right " (click)="handleRemoveFromWishlistitem()"></i>
                 <i *ngIf ="!addedToWishlist" class="far fa-heart fa-lg float-right"    (click)="handleAddToWishlistitem();"></i>
              </p>
              <!--</div>-->
              <div class="-flex justify-content-between align-items-left">
                <div class="btn-group">
                  <button type="submit" [disabled]="!form.valid" class="productitem-sub-btn"><img  src="assets/shopping_cart_outline001.png" class="productitem-img"></button>
                </div>
                
              </div>
              </div>
              </form>
              
              
              
            </div>
        </div>
      </div>
    </div>
  <!--</div>-->
       
          