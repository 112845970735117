<div class="container">
<div class="row" >
<div class="col-md-4 offset-md-4">
<div class="card card-style"  id="reg">
    <h4 class="card-header">Register</h4>
    <!--<router-outlet></router-outlet>  Added router outlet this because alert not showining, check!-->
    <div class="card-body">      
        <form  *ngIf="!isSuccessful" [formGroup]="form" (ngSubmit)="onSubmit()" >
            <div class="form-group">
                <label for="firstName">First Name</label>
                <input type="text"  name="firstName" id="firstName"  autocomplete="off" formControlName="firstName" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                <i class="fas fa-user fa-reg-fn"></i>
                <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                    <div *ngIf="f.firstName.errors.required">First Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="lastName">Last Name</label>
                <input type="text" name="lastName" id="lastName" autocomplete="off"  formControlName="lastName"  class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                <i class="fas fa-user fa-reg-ln"></i>
                <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" name="username" id="username" autocomplete="off"  formControlName="username" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <i class="fas fa-user fa-reg-un"></i>
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" name="password" id="password"  autocomplete="off" formControlName="password" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <i class="fas fa-user fa-reg-pw"></i>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
            <div class="form-group">
                <label for="confirmPassword">Confirm Password</label>
                <input type="password" name="confirmPassword" id="confirmPassword" autocomplete="off"  formControlName="confirmPassword" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <i class="fas fa-user fa-reg-fpw"></i>
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email</label>
                <input type="email"  name="email" id="email"  autocomplet="off" formControlName="email" autocomplete="off" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <i class="fas fa-envelope fa-reg-em"></i>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
            </div>
            <div class="form-group">
                <button type="submit" [disabled]="loading" class="btn btn-primary">
                  
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Register
                </button>
               <!--<button type="button" class="btn" style="background-color:#d30691; color:#FFFFFF"><a routerLink="../home">Cancel</a><button>-->
                   <a routerLink="../home" class="btn" style="background-color:#D30169; color:#FFFFFF">Cancel</a>
                 
            </div>
        </form>
         
    </div>
</div>
</div>
</div>
</div>