import { Injectable } from '@angular/core';
import{ HttpClient, HttpHeaders } from '@angular/common/http';
import { Product} from 'src/app/models/product';
import { BehaviorSubject, Observable } from 'rxjs';
import {map,catchError, tap} from 'rxjs/operators';
import { productUrl } from 'src/app/config/api';

export interface Products{
  _id: string,
  name: string
 
}

//apiUrl= 'http://localhost:3000/products'
//const apiUrl= 'http://localhost:3000/getProducts/someClothing';  //store on config file later

// The @Injectable() decorator is the standard decorator for service classes. 

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  


  getAll(params: any) {
    throw new Error('Method not implemented.');
  }


  rootUrl = '/api'

  
 
  constructor(private _http: HttpClient) { }  // make a service available by dependency injection
   //data type Product
   
   

   getProducts(): Observable<Product[]>{
  //TODO Populate product from an API and return an observable

    return this._http.get<Product[]>(productUrl); //If you spcify data type you must specify everywhere
  }
  //must add some exception handling
  
  getallProducts(): Observable<Products[]>{
    return this._http.get<Products[]>(this.rootUrl + '/getProducts/someClothing')
   
      
     }

     

 

}





