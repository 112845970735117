import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder,UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ContactService } from '@app/services/contact.service';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {


  FormData!: UntypedFormGroup;

  //Take this out for now for contact.

  constructor(private builder: UntypedFormBuilder) {}

  ngOnInit() {
    this.FormData = this.builder.group({
      Fullname: new UntypedFormControl('', [Validators.required]),
      Email: new UntypedFormControl('', [Validators.required]),
      //Email: new UntypedFormControl('', [Validators.compose([Validators.required, Validators.email])]),
      Comment: new UntypedFormControl('', [Validators.required])

  })

}

onSubmit(FormData: any) {
  console.log(FormData)
  //this.contact.PostMessage(FormData)
 /* .subscribe(response => {
  location.href = 'https://mailthis.to/confirm'
  console.log(response)
  }, error => {
  console.warn(error.responseText)
  console.log({ error })
  })*/
  }


}
