import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contact.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HairproductsComponent } from './shopping/hairproducts/hairproducts.component';
import { LayoutComponent } from './ui/layout/layout.component';
import { ProductListComponent } from './shopping-cart/product-list/product-list.component'
//import { CartComponent } from './shopping-cart/cart/cart.component'
import { CartComponent } from './shopping-cart/product-list/cart/cart.component';
import { CartItemComponent } from './shopping-cart/product-list/cart-item/cart-item.component';
//import { CartItemComponent} from './shopping-cart/cart/cart-item/cart-item.component'
import { ClothingComponent } from './shopping/clothing/clothing.component';
import { CheckoutComponent } from './shopping-cart/checkout/checkout.component';

import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';


import { ContactFormComponent } from './contact-form/contact-form.component';

import { WishlistitemComponent } from './shopping-cart/product-list/wishlist-list/wishlistitem/wishlistitem.component';
import { wishlistitemUrl } from './config/api';
import { WishlistListComponent } from './shopping-cart/product-list/wishlist-list/wishlist-list.component';

import { PurseListComponent } from './shopping-cart/product-list/purse-list/purse-list.component';
import { PurseItemComponent } from './shopping-cart/product-list/purse-list/purse-item/purse-item.component';
//import { ShoeListComponent } from './shopping-cart/product-list/shoe-list/shoe-list.component';
//import { ShoeItemComponent } from './shopping-cart/product-list/shoe-list/shoe-item/shoe-item.component';
import { HeaderComponent } from './ui/header/header.component';
import { AuthGuard } from './_helpers';
import { ResetPasswordComponent } from './account/reset-password/reset-password.component';
import { SalelistListComponent } from './shopping-cart/salelist-list/salelist-list.component';


/*added this so can go to particular section when click on nav bar*/
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};



//use this when you only want logged in users to access add later  canActivate:[AuthGuard]

const routes: Routes = [
 {path: '', redirectTo: '/home', pathMatch: 'full'

  },
  { path: 'home', component: LayoutComponent
  },
  
  {
  path: 'aboutus',  component: AboutusComponent
  },
  {
  path: 'contact',  component: ContactFormComponent
  },
  {
  path: 'contactus', component: ContactComponent  

  },
  {
  path: 'hairproducts', component: HairproductsComponent
  },
  {
    path: 'product',  component: ProductListComponent
  },
  {
    path: 'itemcart', component: CartComponent
  },
  {
    path: 'checkout', component: CheckoutComponent
  },
  {
    path: 'login',  component: LoginComponent
  },
  {
    path: 'register',  component: RegisterComponent
  },
   {
    path: 'wishlistitem', component: WishlistitemComponent

  },
  {
    path: 'wishlistlist', component: WishlistListComponent
  },
  {
    path: 'purselist', component: PurseListComponent 
  },
  {
     path: 'purseitem', component: PurseItemComponent
  }, 
  {
     path: 'forgot-password', component: ForgotPasswordComponent 
  },
  {
    path: 'getProducts', component: HeaderComponent
  },
  {
    path: 'reset/:token', component: ResetPasswordComponent

  },
  {
    path: 'sales', component: SalelistListComponent
    
  },

  {
     path: '**',  component: PageNotFoundComponent

  },

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ anchorScrolling: 'enabled', scrollPositionRestoration:'enabled', scrollOffset: [0, 64]})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
