import { Pipe, PipeTransform } from "@angular/core";
import { Product } from "@app/models/product";

@Pipe({
    name: 'productHilter'
})

export class ProductFilerPipe implements PipeTransform {
  transform(productList: Product[], searchTerm: string): Product[] {
      if(!productList || searchTerm){
          return productList;
      }
          return productList.filter(productItem =>
            productItem.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1);
  }

}