import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { purseUrl } from '@app/config/api';
import { Observable, of } from 'rxjs';
import {Wish} from 'src/app/models/wish';
import { Product} from 'src/app/models/product';


 //purseUrl = 'http://localhost:4000/purses';

@Injectable({
  providedIn: 'root'
})
export class PursesService {

  


  constructor(private http:HttpClient) { }



getPurses(): Observable<Product[]>{

  return this.http.get<Product[]>(purseUrl);
}

 

}
