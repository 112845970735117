<div class="row">
 <div class="col-md-4 offset-md-4">
  <div class="card">
<h3 class="card-header">Verify Email</h3>
<div class="card-body">
    <div *ngIf="emailStatus == EmailStatus.Verifying">
        Verifying...
    </div>
    <div *ngIf="emailStatus == EmailStatus.Failed">
        Verification failed, you can also verify your account using the <a routerLink="forgot-password">forgot password</a> page.
    </div>
</div>
</div>
</div>
</div>

