<footer>
 <div class="borderBox"> 
      <div class="section">
        <div class="container">
          <div class="row">          
            <div class="col-md-12 col-sm-6">
              <span class="socialmedia-col">Get connected with us on social networks!</span>
                <div class="social-networks float-right">         
                  <a href="" class="facebook"><span class="sr-only">Visit example.com</span><i class="fab fa-facebook"></i></a>
                  <a href="" class="instagram"><i class="fab fa-instagram"></i><span class="sr-only">Visit example.com</span></a>
            </div>                                                 
        </div>
        </div>
      </div>     
   </div>
 </div>
   <div class="customFooter">
     <div class="container">
       <div class="row">
         <div class="col-sm-4 d-none d-sm-block" >
           <h1 class="footer-h1">&nbsp;DivasandDollsChi.com</h1>
             <ul class="footerStyle footer-ui-style" >
               <li><a routerLink="/home"  class="footerStyle" >Home</a></li>
               <li><a routerLink="/about" class="footerStyle">About Us</a></li> 
             </ul>
         </div>
        
          <div class="col-sm-3 d-none d-sm-block">
            <h2 class="footer-h1-2">Important Links</h2>
              <ul class="footer-ul">
              <li><a href="#!/blog" class="footerStyle">Reviews</a> 
              </li>
              <li><a routerLink="/product" class="footerStyle">Products</a> 
              </li>
             </ul>              
          </div>
          
          <div class="col-sm-3  d-none d-sm-block">
             <button type="button" class="btn btn-light btn-sm" data-toggle="modal" ng-click="openDialog" data-target="#myModal">Let's Chat</button>

 <!-- Modal -->
 <div class="modal fade" id="myModal" role="dialog">
   <div class="modal-dialog">

     <!-- Modal content-->
     <div class="modal-content">
       <div class="modal-header">
         <button type="button" class="close" data-dismiss="modal">&times;</button>
         <h3 class="modal-title">Message</h3>
       </div>
       <div class="modal-body">
         <form name="form" role="form" ng-submit="submitForm(event)" novalidate>
     <div class="form-group footer-form">
        <label for="msg"><b>Message</b></label>      
        <textarea placeholder="Type message.." name="msg"  id="msg" ng-model="chat.msg"   rows="4" cols="50" class="form-control" required></textarea>
      
    </div> 
 
     
<div class="modal-footer"> 
 <button  type="submit" class="btn btn-primary" id="submit" ng-click="submitted = true">Submit</button> 
 <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button>

</div>
</form>
         
   </div>    
     </div>
</div>
 </div>
  </div>
   </div>
    </div>
  
    
<div id="copyrightFooter">
       <div class="row">
       <div class="col-12 col-md-3 centered-on-small">
           <small>&#9400; 2024 DivasandDollsChi</small>
         </div>
        <div class="col-7 col-md-6">
           <a routerLink="/home" id="backToTopLink"><span class="sr-only">DivasandDollsChi.com</span></a>
         </div></div></div><br />
         
       <div class="container">
         <div class="row">
           <div class="col-md-5 float-right">
             <p class="footer-copyright">&#9400;2012-2024 Developed and Maintained By Pamela D. Harper SENSEYSIONAL.com</p>      
           </div>
       </div>
      </div>
     </div>  
</footer>