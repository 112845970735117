import { Component, Input, OnInit, Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/product';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Wish }  from 'src/app/models/wish';
import {ProductService, Products} from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { CartService }  from 'src/app/services/cart.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { WishlistItemService } from '@app/services/wishlist-item.service';
import { WishlistitemComponent } from '../wishlist-list/wishlistitem/wishlistitem.component';
import { WishlistListComponent } from '../wishlist-list/wishlist-list.component';
import { AccountService, AlertService } from 'src/app/_services';
import { AppserviceService } from '@app/services/appservice.service';
//import { ValueService } from '@app/services/value.service';
import { CartItem } from '@app/models/cart-item';
import { HttpClient } from '@angular/common/http';
import { purseUrl } from '@app/config/api';


@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html',
  styleUrls: ['./product-item.component.scss'],
 
})

export class ProductItemComponent implements OnInit {
 // static imageUrl: any;
 
  [x: string]: any;

  form!: UntypedFormGroup; 
  submitted=false;
  sizeBy!: string;

  Valuesize!: string;
  size!: string;
  imageUrl!: string

  
 
//products is my database collection
  //Products is my model/interface for db 
 products:Products[] = [];

 cartItems: CartItem[] = [];
 @Input() cartItem!: CartItem;

  
 @Input() productItem!: Product; 


 @Input()
  addedToWishlist!: boolean; 




 //@Input() addedToWishlistitem: boolean = false;

 //Giving me problem will check it later , private msg: MessengerService,

  constructor( private cartService: CartService,  private formBuilder: UntypedFormBuilder,
    private wishlistService:WishlistService, private _wishlistitemService: WishlistItemService,  private _data:AppserviceService, private alertService: AlertService ) { }


    cartitems$ = this.cartService.cartitems$  //for subscribe to refresh cart
    addedTowishlist = this._wishlistitemService.addedToWishlist

    //: void  hold this for now on ngOnInit
  ngOnInit() {
    this.form = this.formBuilder.group({
        sizeBy: ['', Validators.required]
    });
       
    
    }




 // convenience getter for easy access to form fields
 get f() { return this.form.controls; }

 onSubmit() {



 // alert("Is on submit");
  this.submitted = true;
  
   // reset alerts on submit
   this.alertService.clear();
  

// stop here if form is invalid

if (!this.form.valid) {
        
   // alert("Form invalid");  
  return;;
 } 



 

   if(this.form.valid){
    this.size= this.form.value.sizeBy
    //alert("This is size" + this.size)
   }

   
   this.handleAddToCart();
     
  
  }

handleAddToCart(){



 // alert("Am I getting size" + this.size)


   this.cartService.addProductToCart(this.productItem, this.productItem._id, this.size).subscribe(()=> {
    
   
   
  this.form.reset();


    
   })


}



 handleRemoveFromCart(){
   //alert("hit remove from cart");
   this.cartService.RemoveProductFromCart(this.productItem._id).subscribe(() =>
   console.log("Product with Id deleted", this.productItem._id),
   (err) => console.log(err)
   );

     
    //this.msg.sendMsg(this.productItem)
     console.log("trying to remove from cart ProductItem", this.productItem._id);
   
 }


  handleAddToWishlist(){
   // alert("click works to added to wish list");
     this.wishlistService.addTowishlist(this.productItem._id).subscribe(() =>{
       this.addedToWishlist=true;

     })
     
  }

  handleRemoveFromWishlist(){
    this.wishlistService.removeFromwishlist(this.productItem._id).subscribe(() =>{
      this.addedToWishlist = false;
    })
  

  }

  handleRemoveFromWishlistitem(){
  
   this._wishlistitemService.removeFromWishlistItem( this.productItem._id).subscribe(() =>{
    alert("remove wish list item");
    //this.productItem = product;
    this.addedToWishlist = false;
   this.msg.sendMsg(this.productItem)
})


    this.addedToWishlist = false;
  }


handleAddToWishlistitem(){
   
    this._wishlistitemService.addProductToWishlistItem(this.productItem, this.productItem._id).subscribe(() =>{
     // alert("Get wish list item");
      //this.productItem = product;
      this.addedToWishlist = true
     //this.msg.sendMsg(this.productItem)
  })
}


shareData() {
  this.Valuesize = this.form.value.sizeBy;
  this._data.updateMessage(this.Valuesize);
}


}




