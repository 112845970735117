import { Component, OnInit,AfterContentChecked } from '@angular/core';
import { Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { CartService, Cartitems} from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { ProductService } from 'src/app/services/product.service';
import { AppserviceService } from '@app/services/appservice.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CartItemComponent } from 'src/app/shopping-cart/product-list/cart-item/cart-item.component';
import { DataService } from '@app/services/data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})

export class CartComponent implements OnInit {
 
  Valuesize!: string;
  qty!: number;
  price!: number;
  name!: number;
  size!: string;
  
 
  @Input()
  product!: CartItem;
  @Input()
  cartItem!: CartItem;
  @Input()  id: any
  

 // cartUrl = 'http://localhost:4000/cart';

  val: any;
 // size: any;
 
  
  //carts is my database collection
  //Cart is my model/interface for db 

  
  cartitems$: Cartitems[] = []

 cartItems: CartItem[] = [];
  
   
  cartTotal$ = 0
  itemTotal$= 0
  productTotal$ = 0;

  constructor(private msg: MessengerService, private cartService:CartService, private productService: ProductService, private formBuilder:UntypedFormBuilder, private _data:AppserviceService, private router:Router, private dataService:DataService) { }
 
  
 

  ngOnInit(): void {
     
     this.handleSubscription();
     this.loadCartItems();
    /* this.dataService.getItemsInCart().subscribe( items =>{
      console.log("From shared service", items)
     });*/


  }

 
 
   handleSubscription(){

    this.msg.getMsg().subscribe((product: unknown) => {         //This is an obsevable, unknown is the set of all possible values.
       console.log(this.msg)
      
     // this.addProductToCart(product) 
     // this.loadCartItems();
    })
  }



   loadCartItems(){

     this.cartService.getCartItems().subscribe((items) => {
     this.cartitems$ = items;
     console.log("what is in cartItems" + this.cartitems$)
     this.calcCartTotal();  
     this.calNumberOfItems();
    })
   }



calcCartTotal() {

  
  
 this.cartitems$.forEach((item )=> {
    this.cartTotal$ += ( item.qty * item.price);  
  })
  
  return  this.cartTotal$;

  
}


calNumberOfItems(){

  //alert("Trying to get tolal items");
  for(this.qty=0; this.qty < this.cartitems$.length; this.qty++){
   this.qty;
    
  }
  this.itemTotal$ = this.qty;
  

}




 
  handleClearCart(){

    alert("Inside handelClearCart");

    /*This service will delete all items from cart*/
     //alert("Whats in CartItem" + this.cartItems)
     this.cartService.deleteCartItems().subscribe((items: CartItem[]) => {
       this.calcCartTotal();
       this.calNumberOfItems();
     
     
    }) 

  }


 
 }
