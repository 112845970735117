
<div class="test">
<div class="container" style="margin:0 auto">
    <div class="row no-gutters" style="margin-top: 30px">
      <div class="col-md">    
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/overalls.png"  alt="outfit overalls">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div>

         </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/redonepiece-r.png"  alt="redonepiece" (click)="redOnepiece()">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
      <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/sportoutfit.png"  alt="sportoutfit">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary"> View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/purpleoutfit4.png"  alt="purpleoutfit">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
    </div>
  </div>
  <div class="container">
    <div class="row no-gutters" style="margin-top: 30px">
      <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/PinkToSlick.png"  alt="outfit pink">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/blacklivesmatter1.png"  alt="">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
      <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/BlacklivesMatter-2.1.png"  alt="">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/BlacklivesMatter4.png"  alt="">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
    </div>
  </div>
   <div class="container">
    <div class="row no-gutters" style="margin-top: 30px">
      <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/greyonepiece.png"  alt="grey outfit onepiece">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/slickjacket.png"  alt="blackjacket">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
      <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/greyofftheshoulder.png"  alt="greyoff the shoulder outfit">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
     <div class="col-md">
        <div class="" style="text-align:center; cursor:pointer;">
          <img  class="shacker" src="assets/sexy.png"  alt="laceoutfit">
          <div class="card-body">
            <p class="card-text">
              <small class="text-muted">{{ 35 | currency}}</small></p>
              <p>Text Goes Here</p>
            <div class="">
              <div class="btn-group">
                <button type="button" class="btn btn-sm btn-outline-primary">Add to Cart</button>
                <button type="button" class="btn btn-sm btn-outline-secondary">View Details</button>
              </div>
              
            </div>
          </div> 
        </div>
     </div>
    </div>
  </div>
  </div>
  <nav aria-label="Page navigation">
    <ul class="pagination justify-content-center">
      <li class="page-item"><a class="page-link" href="#">Previous</a></li>
      <li class="page-item active"><a class="page-link" href="#">1</a></li>
      <li class="page-item"><a class="page-link" routerLink="clothing2" routerLinkActive="active">2</a></li>
      <li class="page-item"><a class="page-link" href="#">3</a></li>
      <li class="page-item"><a class="page-link" href="#">Next</a></li>
    </ul>
  </nav>
 
       
 
 <!--</div>-->