<div class="row">
  <div class="col-md-4 offset-md-4">
    <div class="card"style="margin-top: 25px" >
      <h4 class="card-header">Login</h4>
    <!--<alert></alert>-->
     <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Username</label>
                <input  type="text" name="username" id="username" autocomplete="off"   formControlName="username" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.username.errors }"  />
                <i class="fas fa-user fa-username"></i>
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password" >Password</label>
                <input type="password" name="password" id="password"  autocomplete="off" formControlName="password" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <i class="fas fa-user fa-password"></i>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                    <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" name="email" id="email" autocomplete="off" formControlName="email" class="form-control pos" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <i class="fas fa-envelope fa-email"></i>
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email address is required</div>
                </div>
            </div>

            <div class="form-group">
                <button  class="btn btn-primary">
                    <!--<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>-->
                    Login
                </button>
                <!--<a routerLink="../home" class="btn"; style="background-color:#D30169; color:#FFFFFF">Cancel</a>-->
                <!--<button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Login
                </button>-->
                <!--<a routerLink="../register" class="btn btn-primary">Register</a>-->
                <a routerLink="../home" class="btn" style="background-color:#D30169; color:#FFFFFF">Cancel</a>
               <div class="form-group col text-left" class="btn">
                <a routerLink="../forgot-password" class="btn btn-link pr-0" style="font-size: 10px; margin-left: -20px">Forgot Password?</a>
              </div>
                
            </div>
        </form>
    </div>
   
</div>
</div>
</div>