<p>app-modal works!</p>
<div >
  <div class="modal-dialog" >
    <div class="modal-header">
      <button type="button"  class="close" data-bs-dismiss="modal" aria-label="Close" style="Margin-top: -30px"><span aria-hidden="true">&times;</span></button>
     </div>
       <div class="modal-body">
         <div class="container">
           
            <!--<img [src]="product.imageUrl">-->
           </div>
         </div>
       </div>
  </div>
<!--</div>-->
 