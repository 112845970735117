<div class=" container dollsandbag">
  <p class="productlist-p">DIVAS and DOLLS CHI is your one stop shop providing you with the essentials to keep you up to speed in your beauty routine.<img  class="productlist-img"   src="assets/DollsandBags.png"></p></div> 

<div class="container checkout-container" style="height:400px">
  
  <div class="row">
    <div class="col-7">
      <h1 class="choosemethof-pay">Choose Method Of Payment</h1>
      <!--<div disabled="true" id="paypal-button-container" class="pp-button-container" style="margin-top: 50px">-->
       <div class="img-fluid ppal-image" >
        <div class="text-container">
          <p class="float-left">We appreciate your patience as we work relentlisly to get our inventory stocked.</p>
        </div>
       </div>


        
     <!--</div>-->
    
    </div>

    <div class="col-4" >
      <a routerLink="/itemcart" class="checkput-item-route" style="cursor: pointer; margin-left: 100px; text-decoration:underline; font-size:1vw">Back To Cart</a>      
     <div class="container y px checkout-border"  >
      <div  *ngFor="let item of cartItems; let i = index">
      <app-cart-item  [cartItem]="item"></app-cart-item>  
      </div>
     <ul class="checkout-ul" style="padding-top: 30px; font-size: 1vw; padding-left:0px; padding-bottom:0px" >
     <li class="list-group-item">
      <strong>Total Items {{itemTotal$}}</strong>
     </li> 
     <li class="list-group-item">
      <strong>Shipping {{shipping | currency}}</strong>
     </li>
     <li class="list-group-item">
      <strong>Estimated Taxes {{estimatedTax | currency}}</strong>
     </li>
     <li class="list-group-item active">
       <strong>Total: {{ cartTotal | currency}}</strong>  
     </li>
     </ul>

     
   
    </div> 
    </div>
    </div>
    
</div>


