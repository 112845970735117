import { Component, OnInit, Input } from '@angular/core';
import { Product } from 'src/app/models/product'
import { Wish } from 'src/app/models/wish';
import { ProductService } from 'src/app/services/product.service'
import { WishlistService } from 'src/app/services/wishlist.service';
import { WishlistItemService } from '@app/services/wishlist-item.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { PursesService } from '@app/services/purses.service';




@Component({
  selector: 'app-purse-list',
  templateUrl: './purse-list.component.html',
  styleUrls: ['./purse-list.component.scss']
})

export class PurseListComponent implements OnInit {

  @Input() product: any;

  productList: Product[]= [];
  purseitemList: Wish[]= [];
  purseList:  Product[]= [];
  wishlist: number[] = [];

  wishitemList: Wish[]= [];
  wishItems: Wish[] =  [];


  constructor(private pursesService: PursesService, private msg:MessengerService, private wishlistService: WishlistService, private _wishlistitemService: WishlistItemService,) { }

  ngOnInit(): void {
    this.loadPurses();
    //this.loadWishlist();
   // this.loadwishlistList();

  }

 
  loadPurses(){
    alert("loadng Product");
    this.pursesService.getPurses().subscribe((purses) =>{
      this.purseList = purses;
      console.log("These are the purses" + purses)

      
    })
  }

  loadWishlist(){
    alert("loading wish list")
     this.wishlistService.getWishlist().subscribe(productIds => {
        console.log("Load products"  +  productIds);
       this.wishlist = productIds;
 
     })
   }
   
   /*loadwishlistList(){
    alert("Loading wish list item");
    this._wishlistitemService.getWishlistitem().subscribe((items: Wish[]) => {
      console.log("A wish List  Item" + items);
     this.wishItems=items;
  
  })

   }*/

}
