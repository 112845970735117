<div class="row">
 <div class="col-md-4 offset-md-4">
  <div class="card card-style">  
  <div class="card-body">
    <img src="assets/forgotpassword.jpg"  alt="forgotpassword image" />
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="email">Email</label>
            <input type="text" formControlName="email" name="email" id="email"  class="form-control middleInput pos" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <i class="fa fa-envelope fa-mail" ></i>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email is invalid</div>
            </div>
        </div>
        <div class="form-group">
            <button [disabled]="form.invalid" [class.diable]="form.invalid" class="btn btn-primary">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Submit
            </button>
            <a routerLink="../login" class="btn btn-link">Cancel</a>
        </div>
    </form> 
</div>
</div>
</div>
</div>
