import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from 'src/app/_models';
import { ResetPassword } from '@app/models/reset-password';
import { Local } from 'protractor/built/driverProviders';
import { resourceLimits } from 'worker_threads';
import { AlertService } from './alert.service';
//import {jwtDecode} from  'jwt-decode';
import {jwtDecode} from'jwt-decode'
//import * as jwt_decode from 'jwt-decode';





@Injectable({ providedIn: 'root' })
export class AccountService {

    //isLoggedIn: boolean = false;

    session: any;
    username!: string;
    password!: string;


   // userSubject!: BehaviorSubject<User>;
   // public user$: Observable<User>;

   private loginStatus = new BehaviorSubject<boolean>(this.checkLoginStatus());
   
   private UserName = new BehaviorSubject<any>(localStorage.getItem('username'));
  
    

 
     

    constructor(
        private router: Router,
        private http: HttpClient,
        private alertService: AlertService) {}

  


    apiUrl = '/users';

    


    public get userValue(): User {
        return this.UserName.value;
    }


    //Login  Method
    login(username:string, password:string ) {
        // pipe() let you combine multiple functions into a single function
        // pipe90 runs the composedfunction in a sequence

        alert("Does it get to account service")
        return this.http.post<User>(`${this.apiUrl}/authenticate`,  {username,password})
        
           
            .pipe(
               map(result =>{
                //login is successful if there is a jwt token in the response
                if(result && result.token){
                    console.log("what is result" + result);

                    //store user details and jwt token in local storage to keep user logged in between page refresh.

                   this.loginStatus.next(true);
                   //key loginStatus
                   localStorage.setItem('loginStatus', '1')
                   localStorage.setItem('jwt', result.token);
                   //key username
                   localStorage.setItem('username', result.username);
                   this.UserName.next(localStorage.getItem('username'));
                   

                }

                 return result;

               }) 
            );
                



    }

    


   public getUser(_user?: any){
      this.UserName
      //return this.http.get<User>
      return JSON.parse(localStorage.getItem('user')as string); //type cast getItem
      //return JSON.parse(window.sessionStorage.getItem('user'));
   }

    get currrentUserName(){
        return this.UserName.asObservable();
    }

    checkLoginStatus() {

        //return true

     
     
        var loginCookie = localStorage.getItem("loginStatus");
        if(loginCookie = "1"){
            return true;

        }

        return false;

       
    }

    get isLoggedIn(){

        return this.loginStatus.asObservable();

    }




    logout() {

        // Set LoginStatus to false and delete saved cookie
       
    
        alert("Is it getting to log out");
         this.loginStatus.next(false);
         localStorage.removeItem('jwt');
         localStorage.removeItem('username');
         localStorage.setItem('loginStatus', '0');
         this.router.navigate(['../../../login']);
         
         this.alertService.success("Logged out Successful");
         console.log("Logged out successfully")

     
    }

    register(user: User) {
        return this.http.post(`${this.apiUrl}/register`, user);
        //return this.http.post(`${environment.baseUrl}/users/register`, user);
        
    }

    forgotPassword(email: User) {
        return this.http.post(`${this.apiUrl}/forgotpassword/` +email, { email:email});
    }

    validateResetToken(token: string) {
        alert("What is token" + "" +token)
        return this.http.post(`${this.apiUrl}/validate-reset-token/` +token, {token });
    }

   /* 
    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${this.apiUrl}/reset-password`, { token, password, confirmPassword });
    }*/

   resetPassword(resetObj: any) {
        return this.http.post(`${this.apiUrl}/reset-password`,  resetObj);
   }

    sendEmail(email:string){
        return this.http.post(`${this.apiUrl}/sendmail`, {email:email})
    }

    verifyEmail(token: string) {
        return this.http.post(`${this.apiUrl}/verify-email`, { token });
    }

    getAll() {
        return this.http.get<User[]>(`${this.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${this.apiUrl}/users/${id}`);
    }

    update(id: string, params: any) {
        return this.http.put(`${this.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.UserName.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${this.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.id) {
                    this.logout();
                }
                return x;
            }));
    }


    getCurrent(){
        alert("Does current get loaded")
        return this.http.get(`${this.apiUrl}/current`);

    }

    logoutReload() { 
        alert("Inside button click");
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate(['/login']);   
            this.router.navigate([currentUrl]);
        });

    }

}