import { Component, Input, OnInit, Output, Query } from '@angular/core';
import { CartService, Cartitems } from 'src/app/services/cart.service';
import { CartItem } from 'src/app/models/cart-item';
import { Product } from 'src/app/models/product';
import { ProductService} from 'src/app/services/product.service';
//import { ProductListComponent } from '../../shopping-cart/product-list/product-list.component';
import { MessengerService } from 'src/app/services/messenger.service';
//import { wishlistUrl } from 'src/app/config/api';
//import {NgbModal, ModalDismissReasons, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { SearchService, Products} from '@app/services/search.service';
import { User } from '@app/_models/user';
import { AccountService, AlertService } from '@app/_services';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuthService } from '@app/services/auth.service';
import { ImageService } from '@app/services/image.service';
import { AppModalComponent} from '@app/ui/header/app-modal/app-modal.component';
import { ViewChild } from '@angular/core';
import { NgbModal,NgbModalConfig, NgbActiveModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { WishlistService } from 'src/app/services/wishlist.service';
import { Wishitems, WishlistItemService } from '@app/services/wishlist-item.service';
import { ProductItemComponent } from '@app/shopping-cart/product-list/product-item/product-item.component';
import { CartItemComponent } from '@app/shopping-cart/product-list/cart-item/cart-item.component';
import { ProductListComponent } from '@app/shopping-cart/product-list/product-list.component';
//import { compilePipeFromRender2 } from '@angular/compiler/src/render3/r3_pipe_compiler';
import { DataService } from '@app/services/data.service';
import { Wish } from '@app/models/wish';
import { EventEmitter } from '@angular/core'
import { CartComponent } from '@app/shopping-cart/product-list/cart/cart.component';
import { WishlistListComponent } from '@app/shopping-cart/product-list/wishlist-list/wishlist-list.component';


@Component({
  providers:[NgbModal, NgbModalConfig, ProductItemComponent, ProductListComponent, CartItemComponent, EventEmitter, CartComponent, WishlistListComponent],   //NgbModal is a service for mpodal dialog
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})




export class HeaderComponent implements OnInit  {

  [x: string]: any;
 
 // [x: string]: any;

user$!: User;
thumbnails:any;
choice:any;
qty: any;
size: any;
user:any;
  private currentUser$!: User;


@ViewChild('Qpaper') public qpaper: any;

  private userSubject!: BehaviorSubject<User>;

//

  
  //@Input() wishItem!: Product;
  
  
  //@Input()
  // productItem!: Product;
  //@Input() product: any;
  @Input() itemProduct!: Product; 

 /* @Input()
  addedToWishlist: boolean = false;*/
 
  @Input()
  productItem!: Product;


//since trying to get data out of modal
  @Input() test: any
   @Input()  data:any;
   @Output() emitdata: EventEmitter<Object> =  new EventEmitter();
    
  //This is for my search criteria
  //products is my database collection
  //Products is my model/interface for db 

 //products:Array<Products> = []; //either one works
  products:Products[] = [];
  hasQuery:Boolean = false;
  img!: string;
  closeResult!: string;
  form!: UntypedFormGroup; 
  submitted=false;
  sizeBy!: string;     /*The definite assignment assertion is a feature that allows a ! to be placed after instance property and variable declarations to relay to TypeScript that a variable is indeed assigned for all intents and purposes, even if TypeScript's analyses cannot detect so */

  Valuesize!: string;
 

 //productList: Product[]= [];

  searchTerm!: string;
  
  //cartitems is my database collection
  //Cartitems is my model/interface for db 
  cartitems$: Cartitems[] = [];

  cartItems:any [] = []; 
    //  cartItems:any  =[]

  wishitems: Wishitems[] = [];  

  
//wishitems: Array<number> = [1]; for testing array only


  cartTotal = 0;
  itemTotal$ = 0;
  result: any;
  product: any;
  _id!:string;
  currentUser!:string
 

 /* @Input()
  productItem!: Product;*/
 

  name: any;
 // modalRef: any;
  //data:Product | any;
 
//Don't think I need this  public activeModal:NgbActiveModal private productService: ProductService,, 

 // implements OnInit
  constructor( private msg: MessengerService, private cartService:CartService, private accountService: AccountService, private modalService: NgbModal, private config: NgbModalConfig,
    private searchService:SearchService, private auth:AuthService, public prodcomp:ProductItemComponent,
    private _imageService:ImageService, private formBuilder: UntypedFormBuilder,private alertService: AlertService,  private _wishlistitemService: WishlistItemService, private dataService: DataService)

   {
    this.config.backdrop = 'static';
		//this.config.keyboard = false;
   // this.user$=this.accountService.getUser();
  
    //this.dataService.currentCartItems.subscribe((v: any) => (this.itemTotal$ = v));
    
    this.cartService.cartitems$.subscribe((v: any) => (this.cartitems$ = v));
   // this.accoutService.firstName.subscribe(v: any => (this.firstName = v));   
   //this.currentUser$ = accountService.getCurrentUser ().subscribe(user=>this.currentUser$)

  
  }

  
  LoginStatus$!: Observable<boolean>;
  UserName$! : Observable<any>;
  


 totalSub!: Subscription;

  ngOnInit(): void {

    this.form = this.formBuilder.group({
    sizeBy: ['', Validators.required]
  });
  
    
   // getter will return the login status of the user
    this.LoginStatus$ = this.accountService.isLoggedIn;

    //getter return the current value of the loggedin user
    this.UserName$ = this.accountService.currrentUserName;

   
     
    this.handleSubscription();
    
    this.cartService._itemTotals$.subscribe(() =>{
      this.loadCartItems();

    });

  

    
  
    this.loadCartItems();
  
    this.loadwishlistList()
    this.initializeData();
    /*location.reload()
    this.router.navigate(['home']);   //see if this works*/
    


    

    //this.data = this.activeModal;
    
    
     //this.currentUser=this.accountService.getUser();
     //console.log(this.user$ = this.accountService.getUser())
 }

 


initializeData(){

 // alert("does it get to initialize data")

 

this.itemProduct =
 { 
    _id: "666696cb81134e1cbb1958d2",
    name:"Shear Top",
    size: "D",
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    imageUrl:"http://localhost:4200/assets/sexy.png",
    price: 200

  }


}


// convenience getter for easy access to form fields
get f() { return this.form.controls; }

onSubmit() {


 //alert("Is on submit");
 this.submitted = true;
 
  // reset alerts on submit
  this.alertService.clear();

 // alert("On submit here");

  if (this.form.invalid) {
     //alert("Please Choose a size");
   return;

  } 

// stop here if form is invalid

  if (!this.form.invalid){
     // alert("Not invalid");
      this.Valuesize = this.form.value.sizeBy;
      //alert("Product"+ " " + " " + this.Valuesize);
     this.size = this.Valuesize;
       
       this.handlepopupAddToCart(this.itemProduct)
    }
       // this.handlepopupAddToCart(this.itemProduct);
 }


 handleAddToCart(){

  this.Valuesize;
 //alert("Trying to add to shopping cart")

 //alert("Am I getting size" + this.size)


  this.cartService.addProductToCart(this.productItem, this.productItem._id, this.Valuesize).subscribe(()=> {

 console.log("****Does it come back to calling function")
     this.form.reset();
  })

}


handleUpdateCart(){

// alert("When does update cart get called" + this.size);

 this.cartService.UpdateCart(this.productItem, this.productItem._id, this.size).subscribe(()=> {
 })


}




   handleSubscription(){

    this.msg.getMsg().subscribe((_product: unknown) => {         //This is an obsevable, unknown is the set of all possible values.
       console.log(this.msg)
      
     // this.addProductToCart(product) 
     // this.loadCartItems();
    })

   }


   loadCartItems(){
 
    //alert("Loading cart item from back end");
    this.cartService.getCartItems().subscribe((item) => {
     this.cartitems$  = item;
    // alert("A cart item" + item);
     this.calNumberOfItems();
    })
   }

 

  calNumberOfItems(){
   // alert("Trying to get tolal items");
    for(this.qty=0; this.qty < this.cartitems$.length; this.qty++){
     // alert("The toatl is " + this.qty) 
      this.qty
    }
    this.itemTotal$ = this.qty;
    

   
    
  }


  loadUser(){

   // alert("Doest load user get loaded")
   
    this.accountService.currrentUserName
    //alert("What is user" + " " + this.user$)

  }

  
 sendData(event:any){
  
  //console.log(event.target.value);
  let query:string = event.target.value;
  //Will match if query is nothing or only spaces
  let matchSpaces:any=query.match(/\s*/);
  console.log("What about match");
     if(matchSpaces[0] === query){
    console.log("What about query");
      this.products=[];
   console.log("what is in collection", this.products);
      this.hasQuery = false;
    return;

  }
  console.log("Is query still excesseable", query)
  console.log("about to call service")
  this.searchService.searchProducts(query.trim()).subscribe(results =>{
    console.log("does it get pass subscribe")
   
    this.hasQuery = true;
    console.log("Results", results);
    this.products = results;
    console.log("Now whats in products", this.products)
   
  });
 }

 searchImage(name:string){

  console.log(name);
  let query = name;
  
    this._imageService.getImage(query.trim()).subscribe( result =>{
    this.hasQuery = true;
    console.log("Image Results", result);
    this.products = result;
   
  
  this.openDialog();
  }) 
 
}

openDialog(){

  const modalOptions: NgbModalOptions = {
    backdrop: false
  };
  
  

   const modalRef= this.modalService.open(this.qpaper, modalOptions);
   //alert("Whats in this.data" + this.itemProduct)
   modalRef.componentInstance.itemProduct= this.itemProduct
     
   {
    // do sth with emmitedValue
   }
   
   

  // alert("Does it get to dialog");

  


// Get the instance of ActiveModal
//this.data = this.activeModal;

 //alert("What is active data" + this.data)
  
  
}



logout(){
  //lwill clear the local storage values

  this.accountService.logout()
  
 //alert("Removing user from local storage");
  //this.router.navigate(['/account/login']);

 }



 //Working 
 //called from popup
 handleAddToWishlistitem(itemProduct: any){

 // alert("Reached Add To Wish list Item from Popup" + itemProduct._id)
 
  this.productItem = itemProduct;
  //alert("Reached Add To Wish list Item from Popup" + this.productItem._id)

//This does get data from popoup only as objectobject is what is returned
const data = this._wishlistitemService.getDataFromPopup(this.productItem, this.productItem._id).subscribe(()=> {


//alert( data); //as object object*/
});


 //alert("Trying to add to wish list item");

this.addedToWishlist =true;


}


handlepopupAddToCart(itemProduct:any){
 
  //alert("This is itemProduct" + this.itemProduct._id);
  

  this.productItem = itemProduct;
   // alert("What is the Id from popup cart" + this.productItem._id)
  const data = this.cartService.addProductToCartPopoup(this.productItem,this. productItem._id, this.productItem.size).subscribe(() =>{
    //alert( data); //as object object*/
    this.form.reset();

   })
    
    
}





 handleReload(){
  // alert("Handle Reload")
   this._wishlistitemService.buttonClick()
   
   
 }
 



loadwishlistList(){
 // alert("Loading wish list item from back end");
  this._wishlistitemService.getWishlistitem().subscribe((item) => {

    this.wishitems=item;
  // alert("A wish List  Item" + item);

})

}

 setData(itemProduct: Product) {

  console.log("Is this working", itemProduct)
  //throw new Error('Function not implemented.');
}



}




