import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient,HttpRequest } from '@angular/common/http';
import { User } from 'src/app/_models';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl = 'http://localhost:4000/enroll';


  private userSubject!: BehaviorSubject<User>;  //assinment assertion
  public user!: Observable<User>;

 
  constructor(private _http: HttpClient) {
  }


 enroll(user: User) {
   return this._http.post<any>(this.baseUrl,user)  
  
  
    }


  getTypeRequest(url: any) {
  return this._http.get(`${this.baseUrl}${url}`).pipe(map(res => {
  return res;
  }));
  }
  
  postTypeRequest(url: any, payload: any) {
  return this._http.post(`${this.baseUrl}${url}`, payload).pipe(map(res => {

  return res;
  }));
  }
  putTypeRequest(url: any, payload: any) {
  return this._http.put(`${this.baseUrl}${url}`, payload).pipe(map(res => {
  return res;
  }));
  }
}
