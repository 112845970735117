<div class="container">
  <div class="row productitem-row">
    <div class="col-3">
      <img class="img-fluid"  [src]="cartItem.imageUrl"  alt="Cart items"> 
      </div> 
    <div claas="col-1"> 
      
       
      
      <div class=" cartitem-card">  
        <div class="">
         <div class="cartitem card-text">Name&#58;  &nbsp; {{cartItem.name}}</div> 
         <div class="cartitem card-text" >Size&#58; &nbsp; {{cartItem.size}}</div>
         <div class="cartitem card-text"><strong>Price&#58; &nbsp; {{ (cartItem.price) | currency}}</strong></div>
         <!--<div class="cartitem card-text">Quanity&#58; &nbsp;{{cartItem.qty}}</div>-->
          
         </div>
     </div> 
    
   </div>
   <div class="col-4">
   <div class="card float-right cartitem-right">
    <span><i class=" fa-solid fa-trash-can cartitem-can"  (click)="handleRemoveFromCart(); handleReload();"></i></span>    <!--handleReload()-->
   </div>
   </div>
   <!--</div>-->
 </div>
 
 
  </div>
  