<div class="section">
  <div class="container-fluid" >
    <div class="col-md-12"> 
      <div class="row" >
        <div class="col-3">
          <a class="navbar-brand" href="#"><img class="header-img-logo"  src="assets/lCorrectedDivasNDolls-LogoFinal.png"  alt="Divas and Dolls logo"></a>
        </div>
        <div class="col-9 input-group header-form-pos">    
          <form>
            <label for="search">
             <input class="form-control py-2 header-search" id="search"  placeholder="Search" name="search" type="search" (keyup)="sendData($event)">          
             <span class="visually-hidden" style="visibility: hidden;"> For Search Box</span>
            </label>
            <section >
            <p *ngIf="products.length < 1 && hasQuery" >Sorry nothing found</p>
              <ng-template ngFor let-product [ngForOf]="products" let-i="index">
                <div class="header-ng-template">
                  <sp-hr class="mt-1 mb-1" *ngIf="i > 0" /><br>
                    <p  class="header-para" (click)="searchImage(product.name)">{{product.name}}</p>                          
                </div><!--end div layout result-->
             </ng-template >
          </section>
          </form>



<a routerLink="/itemcart"><span><i  class="fa fa-shopping-cart fa_custom fa-2x header-itemcart" aria-hidden="true"></i></span>
<span><i class="fas fa-circle header-circle " aria-hidden="true"><span class="header-itemcart-strong">{{itemTotal$}}</span></i></span></a>            
  <span>
    <div>
      <button class="nav-item btn btn-primary header-btn-register">
        <a class="nav-link header-btn-a-register" routerLink="/register">Register</a>&nbsp;
      </button>
      <button class=" nav-item btn btn-secondary header-btn-login" style="height: 35px; width: 60px; background-color: #D30169; margin-left:5px;"  >
        <a class="nav-link header-btn-a-login"  routerLink="/login" style="margin-left: -15px; margin-top: -6px; font-size:12px; color:#ffffff">LogIn</a>
      </button>

      <button  class=" header-btn-dropdown-user" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-labelledby="navbarDropdown"> 
        <span><i class="fas fa-user header-fs-user" data-toggle="dropdown" ></i></span> 
      </button>
      <span class="loggedin-user">Hello</span>
      <i class="loggedin-user-font" *ngIf="(LoginStatus$ | async) as LoginStatus">
        <a *ngIf="(UserName$ | async) as UserName"> {{UserName | uppercase}}</a>
      </i>
          
      <button  class=" heart-button" aria-label="fa-heart">
        
        <ng-container *ngIf="wishitems.length >0; else emptyWishlist">          
          <span class="fas fa-heart float-right" aria-hidden="true" ></span>
        </ng-container>
      
        <ng-template #emptyWishlist>
          <span class="far fa-heart float-right" aria-hidden="true"></span>
        </ng-template> 
       
      </button>

       <i  *ngIf="(LoginStatus$ | async) as LoginStatus"> 
         <a style="color:#1c004f" *ngIf="LoginStatus" routerLink="sales">Sales</a>
       </i>
                      
      <div class="dropdown-menu dropdown-menu-right header-d-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="#">Profile</a>
          <a class="dropdown-item" routerLink="" (click)="logout()">Logout</a>
            <a class="dropdown-item" routerLink="wishlistlist">Favorites</a>
              <!--<a class="dropdown-item" href="#">Option D</a>-->
      </div>
    </div>  
  </span>
           
              
   </div>  <!--end input group-->
    </div>  <!--end row-->

    <ng-template class="modal"  #Qpaper let-d="dismiss">
      <!--<div class="modal-dialog">-->
        <div class="modal-header header-modal-header">
          <button type="button"   class="btn-close"  aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
             <div class="modal-body">
              <div class="container">
                <div class="row header-row">
                  <div class="col-6"> 
                 <div *ngFor = "let itemProduct of products">
                   <img [src]="itemProduct.imageUrl" alt="Popup Clothing">
                  <div class="col-sm-12">
                    <p class="float-left header-col-para">{{itemProduct.name}}</p>
                    <form name="form"[formGroup]="form" (ngSubmit)="onSubmit()">
                  
                      <alert></alert>
                    
                       <p class="header-form-para">
                     
                       <i *ngIf ="addedToWishlist" class="fas fa-heart float-right"  (click)="handleRemoveItemFromWishListitem()"></i>
                       <i *ngIf ="!addedToWishlist" class="far fa-heart float-right" (click)="handleAddToWishlistitem(itemProduct);"></i>
                    </p>
                    
                    <div class="-flex justify-content-between align-items-left">
                     
                    </div>
                    <!--</div>-->
                    </form>    
                    
                  </div>
                 </div>
                 </div>
                </div>
                </div>
               </div>
             </div>
        <!--</div>-->
    
    </ng-template>
    

     <div class="container-fluid">
      <div class="animated-position">
        <section>
          <div class="container">
            <div class="row"> 
              <div class="col-12 justify-content-center"> 
             <h2>DIVAS</h2>
             <h2>DIVAS</h2>
            </div>
            </div>
         </div>
        </section>
        <div class="header-img"></div>
         </div>
      </div>
    </div>  <!--end col-->
  </div> <!--end container-->
</div>

  <!--</nav>-->
  
  <nav class="navbar navbar-expand-md navbar-light navbar-customcolor">
      <button class="navbar-toggler bg-iconnav" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <br>
    <!--<div class="collapse navbar-collapse"></div>-->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      
      <ul class="navbar-nav mr-auto header-navbar"  id="text">
        <li class="nav-item-active headerlist-style" >
           <a class="nav-link"  routerLink="/home" routerLinkActive="active">Home<span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLink="/aboutus">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/contactus">Contact Us</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Shop
          </a>
          <div class="dropdown-menu dropup" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" >Purses Comming Soon</a>
            <a class="dropdown-item" routerLink="product" >Product</a>
            <!--<a class="dropdown-item" >Shoes Comming Soon</a>-->
            <a class="dropdown-item" >Accessories Comming</a>
            <!--<div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something</a>-->
          </div>
        </li> 
      </ul>
           
         
  </div>       
  </nav>
    <!-- /.container end-->
  
    <div class="nav navborder" ></div>
    <div class="nav navborder"></div>
  
    
    