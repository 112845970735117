import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, finalize } from 'rxjs/operators';
import { AccountService, AlertService } from 'src/app/_services';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  form!: UntypedFormGroup;  //assignment assertion
  loading = false;
  submitted = false

  constructor( private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private alertService: AlertService, private authService:AuthService ) { }
    ngOnInit() {
      this.form = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email]]
      });
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {
      this.submitted = true;

      // reset alerts on submit
      this.alertService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
          return;
      }

      this.loading = true;
      //this.alertService.clear();
     // this.accountService.sendEmail(this.form.value.emai)
      this.accountService.sendEmail(this.form.value.email)
         // .pipe(first())
          .pipe(finalize(() => this.loading = false))
          .subscribe({
              next: () => this.alertService.success('Please check your email for password reset instructions'),
              error: error => this.alertService.error(error)
              
          });
          this.form.reset();
  }

  

}
