import { Component, OnInit } from '@angular/core';

import { User } from '@app/_models';
import { AccountService } from '@app/_services';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  user: User;

  constructor(private accountService: AccountService) { 
    this.user = this.accountService.userValue;  

  }

  ngOnInit(): void {
  }

}
