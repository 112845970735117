import { Component, OnInit, Input } from '@angular/core';
import { CartItem } from '@app/models/cart-item';
import { CartService, Cartitems }  from 'src/app/services/cart.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ProductItemComponent } from '@app/shopping-cart/product-list/product-item/product-item.component';
import { AppserviceService } from '@app/services/appservice.service';
import { cartUrl } from '@app/config/api';
import { tap } from 'rxjs/operators'




@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent implements OnInit {

  
 
  @Input()
  cartItem!: CartItem;

 
  addProperty: any;
  Valuesize!: string;

  //items: CartItem[] = []
 
  //carts is my database collection
  //Cartitems is my model/interface for db 
   cartitems$:   Cartitems[] = []

 
   cartItems: CartItem [] = []
  
  form!: UntypedFormGroup; 
  qty!: number;
  productTotal!: number 
  size!: string
  value = 1;
  someitem = 0;




// Not used in constirctor

  constructor(private cartService: CartService) {
   
    this.cartService.cartitems$.subscribe((v: any) => (this.cartitems$ = v));
    
    //this.cartService.cartitems.subscribe((val) => (this.cartitems = val))
  }

  //hold : void
  ngOnInit() {

  

  }

// convenience getter for easy access to form fields
//get f() { return this.form.controls; }




  handleRemoveFromCart(){
    //alert("hit remove from cartitem");
    this.cartService.RemoveProductFromCart(this.cartItem._id).subscribe(() =>{

      console.log("Product with Id deleted", this.cartItem._id)
 
      //Remove item from array

      

    }
     


      
    );
  
   
  }


  

   handleReload(){    //This is for the remove will fix this
    this.cartService.buttonClick();
    
    
  }

      

  increment() {
    this.value++;
  }

  decrement() {
    this.value--;
  }

    calNumberOfProducts(){
     
     // alert("Trying to get tolal items");
        for(this.qty=0; this.qty < this.cartitems$.length; this.qty++){
        this.qty;
    
    }
       this.productTotal = this.qty + this.value;

    
    }

   
}
