import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
//import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import  {DomSanitizer, SafeUrl }  from '@angular/platform-browser';
import { Product } from '../models/product';
import { cartUrl} from '../config/api';

export interface Products{
 _id: string,
  name: string,

}

@Injectable({
  providedIn: 'any'     //change this from root to any
})
export class ImageService {

  query!: string; //assignment assertion 

  //cartUrl = 'http://localhost:4000/cart';
  


  constructor(private _http:HttpClient) { }

rootUrl ='/api'

   getImage(query:string) {
     return this._http.post<{payload:Products[]}>(this.rootUrl + '/getProducts/imageName', {payload: query}, {
     
      headers: new HttpHeaders({'Content-Type': 'application/json'})       
     }).pipe(
       map(data => {
         console.log('API Response => ', data);
         return data.payload
       })
     
    );
          
      
     }

   
     /*Must get Item from MongoDB */
    /* addProductToCart(products:Products,Valuesize):Observable<any>{
      console.log("Am I getting parameters" + Valuesize + " " + products)
      Object.defineProperties(products,{
        
        size:{
        value:Valuesize,
        writable: false
        }
        
      });
      return this._http.post(cartUrl, {products})
     }*/
      
   }

   