import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hairproducts',
  templateUrl: './hairproducts.component.html',
  styleUrls: ['./hairproducts.component.scss']
})
export class HairproductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
