import { Injectable,inject } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { CartItem } from '../models/cart-item';

import { cartUrl} from '../config/api';
import { Product } from '../models/product';
import { Alert } from 'selenium-webdriver';
import { BehaviorSubject,Subject } from 'rxjs';
import { NgbModal, NgbActiveModal,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { Products } from './product.service';
import { DataService } from './data.service';
import { CartItemComponent } from '@app/shopping-cart/product-list/cart-item/cart-item.component';

export interface Cartitems{
  _id: string,
  name: string,
  size: string,
  qty: number,
  price: number,
  imageUrl:string
}



@Injectable({
  providedIn: 'root'
 
})

export class CartService {

  [x: string]: any;

  size!: string;
  postId!: string;
  //product: any;

  cartIdsArray:any = [];
  //itemTotal$ = 0;

  

    
 
  rootUrl = 'api';


  
 cartitems$= new BehaviorSubject<Cartitems[]>([]);
 itemTotals$  = new Subject<Cartitems[]>();
 cartTotal$ = new Subject<Cartitems[]>();

 
 get _itemTotals$(){
   return this.itemTotals$
 }

 get _cartTotal$(){
   return this.cartTotal$
 }
  

  data: any;
  name: any;
  products : Products[] = [];
  

  //private route: ActivatedRoute, private modalService: NgbModal  Taking these out not being used will cause problem in unit test spec

  constructor( private http: HttpClient, private router: Router, private dataService: DataService ) {
 }

//To do  mapping the obtained results out to card items props pipe and mapp functions will be used.
 //The observable returneded by carUrl is the source is going to pipe through the map projection anf get results of CartItem
   getCartItems(): Observable<Cartitems[]> {

    
     return this.http.get<Cartitems[]>(this.rootUrl + '/getProducts/cartitems')
   
   }
 


 addProductToCart(product:Product, _id:String, size:String): Observable<Product>{
 
  alert("what is size" + size)
    


   return this.http.post<any>(this.rootUrl + '/getProducts/addtocart/' + _id + '/' + size,   {product})
 
       //return this.http.post<any>(this.rootUrl + '/getProducts/addtocart/', {products})
   
  .pipe(
    tap(() => {
      this.itemTotals$.next();
    })
  );

 }



UpdateCart(product:Product, _id:String, size:String):Observable<Product>{

  alert("Service update cart")



  return this.http.post<any>(this.rootUrl + '/getProducts/updatecart/' + _id + '/' + size, {product})


}



addProductToCartPopoup(product:Product, _id:String, size:String ):Observable<any>{
 
  alert("Does it get to popup service" );

  alert("What is id" + _id);

return this.http.post(this.rootUrl + '/getProducts/addtocartpopup/' + _id + '/' + size, {product})

}




     /*This workd but need more work should not have to refresh*/
     RemoveProductFromCart(_id:string){  // changed this from void to unknown, remeber any turn off typechecking
     alert("show deleted item" + _id);
     return this.http.delete<Cartitems[]>(this.rootUrl + '/getProducts/removecartitems/' + _id);
     
   
      
     
     
    }

  

    /* Done with function call to component from checkout after payment success which in turn calls this service*/ 
    /*Below was for when i was using Json and fake backend
    /*deleteCartItems(): Observable<CartItem[]> {
      return this.http.get<CartItem[]>(cartUrl).pipe(
       map((result: any[]) => {
         
         let cartItems: CartItem[] =[];       
 
         for(let item of result) {
         
          cartItems.push( new CartItem(item.id,  item.product, item.imageUrl ));
            alert("whats in item" + item._id);
              this.cartIdsArray = cartItems.map(item => item._id)
                let id = item.id;
                let endPoints = "/cart/";

              this.cartIdsArray.forEach((id: string) =>  this.http.delete(this.url + endPoints + id).subscribe(data=>{
              console.log(data);
            
            }))
            
           
        }
          
         return cartItems;
         

        })
      );
         
         
    }*/

    buttonClick() { 
      alert("Inside button click");
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
  
        //alert("Should Reload");
    } 
  

   /* addPurseToCart(product:Product):Observable<any>{
      return this.http.post(cartUrl, {product})


    }*/

     
     
}
