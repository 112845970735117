import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first, map } from 'rxjs/operators';


import { AccountService, AlertService } from 'src/app/_services';
import { ApiService } from 'src/app/services/api.service';
import { Token } from '@angular/compiler';
//import { AuthService } from 'src/app/services/auth.service';





@Component({ 
    selector: 'app-login',
    templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  
  //isLoggedIn: boolean = false;
  isSuccessful: boolean = false;
   errorMessage = " ";
   
   //! afer property name is a definite assingment assertion if you don't use it will get error saying no initiallized

    form!: UntypedFormGroup;  //assignment assertion
    loading = false;
    submitted = false;
    returnUrl!: string;
    //not sure if I will use this
    public resetPasswordEmail!: string;
    public isValidEmail!: boolean;
    username!: string;
    password!: string;
    email!: string;
    message!: any





    constructor(
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
       // private _api: ApiService,
       // private _auth: AuthService,
    ) {// redirect to home if already logged in
        if (this.accountService.userValue) { 
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.form = this.formBuilder.group({
            username: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            email:    ['', [Validators.required, Validators.email]]
            
        });
        
    }

    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }

    onSubmit() {
        alert("Does it get to submit for Login");
        
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.form.invalid) {
            alert("Something went wrong form invalid");
            return;

        }

        this.username = this.form.value.username;
        this.password = this.form.value.password;
        this.email = this.form.value.email;

        let user ={
            username:this.form.value.username,
            password: this.form.value.password

        }


        alert("Login Credintials" + " " + this.username  + " " + this.password + " " +this.email);


        this.loading = true;
       
        this.accountService.login(this.form.value.username,this.form.value.password )
            .pipe(first())
            .subscribe({
                next: (response) => {
                    let token =(<any>response).token
                    alert("What is token"+   " " + token)
                    
                    //handle response data here

                  alert("what is the response" + "" +response);
                  
                  this.alertService.success("Login Successful");
                  this.form.reset();
                  
              
                },
                error: (err) =>{
                    

                    alert(("what is error" + " " +err))
                    console.error("Error:",err)
                    this.alertService.error(err)


                },
                    
               
                
            });

           
           
    }




}